<script setup>
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import EventCardMember from '@/Pages/Dashboard/Pages/Events/Components/EventCardMember.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';
import CreatorRecord from '@/Pages/Dashboard/Shared/CreatorRecord.vue';

const props = defineProps({
    visitor: Object,
    event: Object,
    creatorRegistration: Object,
});

import useDateFormat from '@/Pages/Dashboard/Composables/useDateFormat.vue';

const { simplifyDate } = useDateFormat();
</script>

<template>
    <Dashboard :page-title="visitor.member.name">
        <div id="show-member">
            <Breadcrumb
                :current-page-name="visitor.member.name"
                :link-list="[
                    {
                        previous_page_url: 'visitors',
                        previous_page_name: 'Visitantes',
                    },
                ]"
            />

            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">{{ visitor.member.name }}</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/members/${visitor.member.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Ver membro
                    </Link>
                    <Link
                        :href="`/dashboard/visitors/edit/${visitor.id}`"
                        class="btn btn-primary btn-add me-2"
                        v-if="event"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                        Editar dados
                    </Link>
                </div>
            </div>

            <form class="card-simple mb-3 p-4" action="">
                <fieldset disabled>
                    <div class="row">
                        <div class="col-md-4">
                            <label for="current_church" class="form-label font-bold">Crente</label>
                            <input
                                id="current_church"
                                type="text"
                                autocomplete="off"
                                class="form-control"
                                :value="visitor.believer ? 'Sim' : 'Não'"
                            />
                        </div>
                        <div class="col-md-8 mt-2 mt-lg-0">
                            <label for="current_church" class="form-label font-bold"
                                >Telefone de contato</label
                            >
                            <input
                                id="current_church"
                                type="text"
                                autocomplete="off"
                                class="form-control"
                                :value="
                                    visitor.member.phone_number?.phone_number || 'Não informado'
                                "
                            />
                        </div>
                    </div>
                    <div class="my-3 row d-flex align-items-center">
                        <label for="staticEmail" class="col-sm-4 col-form-label"
                            >Como conheceu a igreja?</label
                        >
                        <div class="col-sm-8">
                            <Badge :name="visitor.met_church" />
                        </div>
                    </div>
                </fieldset>
            </form>

            <EventCardMember :event="event" v-if="event" />

            <template v-if="visitor.connection_group_meeting">
                <div class="px-5 py-4 mb-3 card-simple position-relative mt-5">
                    <div class="position-absolute top-0 start-50 translate-middle">
                        <img
                            class="rounded-circle"
                            width="50"
                            :src="`/storage/${visitor.connection_group_meeting.group_connection_photo}`"
                            alt=""
                        />
                    </div>
                    <div class="font-semibold text-center fs-14 mt-3">
                        Essa visita foi realizada durante um encontro do
                        <Link
                            :href="`/dashboard/group-connections/${visitor.connection_group_meeting.group_connection_id}/meetings/${visitor.connection_group_meeting.id}`"
                            >{{ visitor.connection_group_meeting.group_connection_name }}</Link
                        >
                        em {{ simplifyDate(visitor.connection_group_meeting.meeting_date) }}
                    </div>
                </div>
            </template>

            <CreatorRecord
                v-if="creatorRegistration.name"
                :data="creatorRegistration"
                title="Visitante cadastrado por: "
            />
        </div>
    </Dashboard>
</template>
