<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    disciplines: Object,
});

const goToDisciplinePage = (disciplineID) =>
    router.get(`/dashboard/disciplines-discipleship/${disciplineID}`);

const clearQueryParams = () => router.replace('/dashboard/disciplines-discipleship');

const searchForm = reactive({
    name: null,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/disciplines-discipleship', searchForm, { preserveState: true });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Disciplinas">
        <Breadcrumb current-page-name="Disciplinas" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Disciplinas</h4>
                <Link
                    :href="`/dashboard/disciplines-discipleship/create`"
                    class="btn btn-primary btn-add"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row g-3 px-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome da disciplina</label>
                        <input
                            id="floatingInput"
                            type="email"
                            v-model="searchForm.name"
                            class="form-control"
                            :value="` `"
                            placeholder="Fundamentos da Fé"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                </div>

                <div class="row p-3">
                    <div class="table-responsive">
                        <table
                            class="table m-0 align-middle table-hover"
                            id="table-disciplines-discipleship"
                        >
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome da disciplina</th>
                                    <th class="text-center" scope="col">Data de criação</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(discipline, disciplineIndex) in disciplines.data"
                                    :key="disciplineIndex"
                                    @click="goToDisciplinePage(discipline.id)"
                                >
                                    <td class="text-center">
                                        {{ disciplineIndex + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-pill me-3"
                                            height="35"
                                            width="35"
                                            :src="`/storage/${discipline.photo_identification}`"
                                            alt=""
                                        />
                                        <span>
                                            {{ discipline.name }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        {{ discipline.created_at }}
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="2">
                                        <Pagination
                                            :data="disciplines.meta"
                                            reference-date="disciplines-discipleship"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
