<script setup>
import { useForm, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    discipleship: Object,
    instructors: Object,
    discipline: Object,
    instructorDisciplineDiscipleship: Object,
});

const form = useForm({
    instructor_id: props.instructorDisciplineDiscipleship.discipleship_instructor_member_id,
});

const update = () =>
    form.put(
        `/dashboard/discipleships/${props.discipleship.id}/disciplines/${props.instructorDisciplineDiscipleship.id}`
    );

const toggleInstructorSelection = (instructor_id) => {
    form.instructor_id = form.instructor_id === instructor_id ? null : instructor_id;
};
</script>

<template>
    <Dashboard page-title="Editar disciplina">
        <Breadcrumb
            current-page-name="Editar disciplina"
            :link-list="[
                {
                    previous_page_url: `discipleships/${discipleship.id}/disciplines`,
                    previous_page_name: `${discipleship.name}`,
                },
            ]"
        />
        <div id="create-minister">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar {{ discipline.name }}</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/discipleships/${discipleship.id}/disciplines/${instructorDisciplineDiscipleship.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="!form.instructor_id"
            >
                <legend class="mb-4">Selecionar o novo instrutor</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in instructors"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`${item.member.identification_photo}`"
                                :class="{
                                    selected: form.instructor_id == item.id,
                                }"
                                @click="toggleInstructorSelection(item.id)"
                            />
                            <span
                                v-show="form.instructor_id == item.id"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.member.clean_name }}</span>
                            <input type="checkbox" name="image[]" value="" />
                        </label>
                    </div>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
