<script setup>
import { Link, router } from '@inertiajs/vue3';

import Layout from '../Layout.vue';

const props = defineProps({
    department: Object,
    scales: Object,
});

const goToScale = (scaleID) =>
    router.get(`/dashboard/departments/${props.department.id}/scales/${scaleID}`);
</script>

<template>
    <Layout :department="department">
        <div
            class="row d-flex justify-content-center px-4 py-3 search-form-card border-0 shadow-none"
        >
            <div class="d-flex justify-content-between mb-2 align-items-center">
                <h5 class="font-bold mb-0">Escalas desse departamento</h5>
                <Link
                    class="btn btn-primary btn-redirection"
                    :href="`/dashboard/departments/${department.id}/scales/create`"
                >
                    Nova
                </Link>
            </div>

            <hr />

            <div class="table-responsive" v-if="scales.length">
                <table class="table align-middle table-hover" id="table-members">
                    <thead class="table-light">
                        <tr>
                            <th>#</th>
                            <th class="text-center">Referente</th>
                            <th class="text-center">Data de início</th>
                            <th class="text-center">Data de fim</th>
                            <th class="text-center">Resumo</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(item, index) in scales"
                            :key="index"
                            @click="goToScale(item.id)"
                        >
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td class="text-center">
                                {{ item.month_year }}
                            </td>
                            <td class="text-center">
                                {{ item.start_date }}
                            </td>
                            <td class="text-center">
                                {{ item.end_date }}
                            </td>
                            <td class="text-center">
                                <a
                                    class="btn btn-danger ms-2 fw-bold fs-12"
                                    :href="`/dashboard/departments/${department.id}/scales/export/${item.id}`"
                                >
                                    <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />
                                    PDF
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <p
                    class="p-3 bg-info fs-14 bg-opacity-10 rounded-end font-semibold border-primary border-start border-5"
                >
                    Nenhuma escala criada ainda
                </p>
            </div>
        </div>
    </Layout>
</template>
