<script setup>
import { router, Link } from '@inertiajs/vue3';

import Layout from '../Layout.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    group: Object,
    members: Object,
});

const statusFrequency = (status) => {
    const style = {
        PENDING: 'Não informado',
        PRESENT: 'Presente',
        ABSENT: 'Ausente',
    };

    return style[status];
};

const formatStatusIcon = (status) => {
    const style = {
        PENDING: {
            color: 'text-body-tertiary',
        },
        PRESENT: {
            color: 'text-success',
        },
        ABSENT: {
            color: 'text-danger text-opacity-50',
        },
    };

    return style[status]?.color || 'text-success';
};

const goToMemberPage = (memberID) =>
    router.get(`/dashboard/group-connections/${props.group.id}/members/${memberID}`);
</script>

<template>
    <Layout :group="group">
        <div
            class="row d-flex justify-content-center px-4 py-3 search-form-card border-0 shadow-none"
        >
            <div class="d-flex justify-content-between mb-2 align-items-center">
                <h5 class="font-bold mb-0">{{ members.length }} membros nesse grupo</h5>
                <Link
                    class="btn btn-primary btn-redirection"
                    :href="`/dashboard/group-connections/${group.id}/members/create`"
                >
                    Novo
                </Link>
            </div>
            <hr />
            <div class="table-responsive">
                <table class="table align-middle table-hover" id="table-members">
                    <thead class="table-light">
                        <tr>
                            <th>#</th>
                            <th>Nome do membro</th>
                            <th class="text-center">Função</th>
                            <th class="text-center" scope="col">Últimos encontros</th>
                            <th class="text-center" scope="col">Presença</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(item, index) in members"
                            :key="index"
                            @click="goToMemberPage(item.id)"
                        >
                            <td class="text-center">
                                {{ index + 1 }}
                            </td>
                            <td>
                                <img
                                    class="rounded-circle me-3"
                                    width="30"
                                    :src="item.member.identification_photo"
                                    alt=""
                                />
                                <span>
                                    {{ item.member.clean_name }}
                                </span>
                            </td>
                            <td class="text-center">
                                <Badge :name="item.function" />
                            </td>
                            <td class="text-center">
                                <div v-if="item.frequencies.length">
                                    <font-awesome-icon
                                        :class="`fs-4 me-2 ${formatStatusIcon(item.status)}`"
                                        :icon="['fas', 'circle-check']"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :data-bs-title="statusFrequency(item.status)"
                                        v-for="(item, index) in item.frequencies.slice(0, 3)"
                                        :key="index"
                                    />
                                </div>
                                <div v-else>Pendente</div>
                            </td>
                            <td class="text-center">{{ item.presence_percentage }} %</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </Layout>
</template>
