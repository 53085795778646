<script setup>
import { reactive, defineProps } from 'vue';
import { Link, router } from '@inertiajs/vue3';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import GroupConnectionCard from './Components/GroupConnectionCard.vue';

const props = defineProps({
    groups: Object,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const searchForm = reactive({
    name: null,
});

const seacher = () =>
    router.get('/dashboard/group-connections', searchForm, {
        preserveState: true,
    });
</script>

<template>
    <Dashboard page-title="Grupos Conexões">
        <Breadcrumb current-page-name="Grupos Conexões" />
        <div class="row mt-3 mb-4">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Grupos Conexões</h4>
                <Link href="/dashboard/group-connections/create" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
                <div class="font-bold col-12 col-sm-6">Baixar Grupos</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="`/dashboard/group-connections/pdf/export`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="`/dashboard/group-connections/excel/export`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>
            <hr class="mx-3" />
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome do departamento</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            :value="` `"
                            placeholder="Grupo dos Jovens"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                </div>
            </div>

            <div class="row mt-3 px-3">
                <hr />
                <GroupConnectionCard :data="groups.data" />
                <Pagination :data="groups.meta" reference-date="group-connections" />
            </div>
        </div>
    </Dashboard>
</template>
