<script setup>
import { ref } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    discipline: Object,
});

const form = useForm({
    name: props.discipline.name,
    photo_identification: `/storage/${props.discipline.photo_identification}`,
    description: props.discipline.description,
});

const inputRef = ref(null);

const update = () => form.put(`/dashboard/disciplines-discipleship/${props.discipline.id}`);

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.photo_identification = e.target.result;
    };
    reader.readAsDataURL(blob);
};

const disciplineEditModalCloseButton = ref(null);

const destroy = () => {
    disciplineEditModalCloseButton.value.click();
    router.delete(`/dashboard/disciplines-discipleship/${props.discipline.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar disciplina">
        <Breadcrumb
            current-page-name="Editar disciplina"
            :link-list="[
                {
                    previous_page_url: 'disciplines-discipleship',
                    previous_page_name: 'Disciplinas',
                },
            ]"
        />
        <div id="create-discipline">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/disciplines-discipleship/${discipline.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#disciplineEditModal"
                        href="#"
                        class="btn btn-danger btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                        Deletar
                    </a>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="form.photo_identification"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    class="alert alert-danger mx-2"
                    role="alert"
                    v-if="form.errors.photo_identification"
                >
                    {{ form.errors.photo_identification }}
                </div>

                <div class="col-md-12">
                    <label for="name" class="form-label"> Nome da disciplina </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Verbo da Vida Paulo Afonso"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="description" class="form-label"> Descrição </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="description"
                        v-model="form.description"
                        type="text"
                        class="form-control"
                        name="description"
                        placeholder="Forneça alguma descrição para essa disciplina"
                        rows="5"
                    />

                    <small v-if="form.errors.description" class="text-danger mt-3">
                        {{ form.errors.description }}
                    </small>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />
            </Form>

            <ConfirmationModal
                modal_id="disciplineEditModal"
                modal_title="Confirmação de remoção do registro"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir o registro?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="disciplineEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar registro
                    </button>
                </template>
            </ConfirmationModal>

            <PhotoEditModal
                v-if="form.photo_identification"
                title="Adicionar foto"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>
