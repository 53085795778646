<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    department: Object,
});

const form = useForm({
    start_date: null,
    end_date: null,
});

const store = () => form.post(`/dashboard/departments/${props.department.id}/scales`);
</script>

<template>
    <Dashboard page-title="Criar escala">
        <Breadcrumb
            current-page-name="Criar escala"
            :link-list="[
                {
                    previous_page_url: `departments/${department.id}/scales`,
                    previous_page_name: 'Escalas',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Criar escala</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-6">
                    <label for="start_date" class="form-label"> Data de início </label>

                    <input
                        id="start_date"
                        v-model="form.start_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                        placeholder=""
                        required
                    />
                    <small v-if="form.errors.start_date" class="text-danger mt-3">
                        {{ form.errors.start_date }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="end_date" class="form-label"> Data de fim </label>

                    <input
                        id="end_date"
                        v-model="form.end_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                        placeholder=""
                        required
                    />
                    <small v-if="form.errors.end_date" class="text-danger mt-3">
                        {{ form.errors.end_date }}
                    </small>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
