<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    members: Object,
    schedules: Object,
    typesIndications: Object,
});

const goToVisitorPage = (memberId) => router.get(`/dashboard/visitors/${memberId}`);

const clearQueryParams = () => router.replace('/dashboard/visitors');

const searchForm = reactive({
    name: null,
    met_church: '',
    schedule_id: 0,
    start_date: '',
    end_date: '',
    believer: '',
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/visitors', searchForm, {
        preserveState: true,
    });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Visitantes">
        <Breadcrumb current-page-name="Visitantes" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Visitantes</h4>
                <Link :href="`/dashboard/visitors/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
                <div class="font-bold col-12 col-sm-6">Baixar visitantes</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="`/dashboard/visitors/pdf/export?name=${searchForm.name}&met_church=${searchForm.met_church}&believer=${searchForm.believer}&schedule_id=${searchForm.schedule_id}&start_date=${searchForm.start_date}&end_date=${searchForm.end_date}`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="`/dashboard/visitors/excel/export?name=${searchForm.name}&met_church=${searchForm.met_church}&believer=${searchForm.believer}&schedule_id=${searchForm.schedule_id}&start_date=${searchForm.start_date}&end_date=${searchForm.end_date}`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>
            <hr class="mx-3" />
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-6">
                        <label class="form-label" for="floatingInput">Nome do visitante</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            :value="` `"
                            placeholder="Aline Andrade"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                    <div class="col-6 col-sm-3">
                        <label for="startDate" class="form-label">Data de início</label>
                        <input
                            id="startDate"
                            v-model="searchForm.start_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-6 col-sm-3">
                        <label for="endDate" class="form-label">Data de fim</label>
                        <input
                            id="endDate"
                            v-model="searchForm.end_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-md-4">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Selecionar programação</label
                        >
                        <select
                            id="schedule_id"
                            v-model="searchForm.schedule_id"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todas as programações</option>
                            <option
                                v-for="(item, index) in schedules"
                                :key="index"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="type" class="form-label">Como conheceu a igreja</label>
                        <select
                            id="type"
                            v-model="searchForm.met_church"
                            class="form-select form-select"
                            aria-label="Small select example"
                            @change="seacher()"
                        >
                            <option value="">Todas opções</option>
                            <option
                                v-for="(item, index) in typesIndications"
                                :key="index"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Situação do visitante</label
                        >
                        <select
                            id="believer"
                            v-model="searchForm.believer"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="">Todas</option>
                            <option value="1">Somente crentes</option>
                            <option value="0">Somente não convertidos</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row px-4 mt-3">
                <hr />
                <div class="table-responsive">
                    <table class="table align-middle table-hover" id="table-visitors">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">#</th>
                                <th class="text-center" scope="col">Nome do visitante</th>
                                <th class="text-center" scope="col">Programação</th>
                                <th class="text-center" scope="col">Crente</th>
                                <th class="text-center" scope="col">Data de visita</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(member, index) in members.data"
                                :key="index"
                                @click="goToVisitorPage(member.id)"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="text-center">
                                    {{ member.member.clean_name }}
                                </td>
                                <td class="text-center">
                                    <span v-if="member.schedule_name">{{
                                        member.schedule_name
                                    }}</span>

                                    <span
                                        v-else
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Dado do SGI antigo"
                                    >
                                        <font-awesome-icon :icon="['fas', 'minus']" />
                                    </span>
                                </td>
                                <td class="text-center">
                                    <Badge :name="member.believer ? 'YES' : 'NOT'" />
                                </td>
                                <td class="text-center">
                                    {{ member.created_at }}
                                </td>
                            </tr>
                        </tbody>

                        <tfoot class="bg-white">
                            <tr>
                                <td colspan="5">
                                    <Pagination :data="members.meta" reference-date="members" />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
