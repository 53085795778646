<script setup>
import { ref, onMounted, computed } from 'vue';
import draggable from 'vuedraggable';
import { useForm, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    discipleship: Object,
    members: Object,
});

const form = useForm({
    open: props.discipleship.open ? true : false,
    name: props.discipleship.name,
});

const discipleshipMembers = ref(props.members);

const transformMembers = (members) => {
    return members.map((item) => ({
        id: item.id,
        status: item.status,
    }));
};

const update = () =>
    form
        .transform((data) => ({
            ...data,
            members: transformMembers(discipleshipMembers.value),
        }))
        .put(`/dashboard/discipleships/${props.discipleship.id}`);
</script>

<template>
    <Dashboard page-title="Editar turma">
        <Breadcrumb
            current-page-name="Editar turma"
            :link-list="[
                {
                    previous_page_url: `discipleships/${discipleship.id}`,
                    previous_page_name: `${discipleship.name}`,
                },
            ]"
        />

        <div id="edit-classe">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar turma</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/discipleships/${discipleship.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="row">
                    <div class="col-12 p-4">
                        <div class="col-md-12">
                            <label for="name" class="form-label"> Nome da turma </label>

                            <input
                                id="name"
                                v-model="form.name"
                                type="text"
                                autocomplete="off"
                                class="form-control"
                                placeholder="Turma Maio 2025"
                                required
                            />
                            <small v-if="form.errors.name" class="text-danger mt-3">
                                {{ form.errors.name }}
                            </small>
                        </div>

                        <hr />

                        <div class="form-check d-flex align-itemscenter">
                            <input
                                class="form-check-input"
                                v-model="form.open"
                                type="checkbox"
                                id="defaultCheck1"
                            />
                            <label class="form-check-label ms-3" for="defaultCheck1">
                                Turma se encontra aberta?
                            </label>
                        </div>

                        <template v-if="members.length">
                            <hr />
                            <legend class="mb-4">Situação final de cada membro</legend>

                            <div class="list-group frequency-presence-members">
                                <div
                                    v-for="(item, index) in members"
                                    :key="index"
                                    class="list-group-item font-semibold fs-12 p-3 mb-2 border rounded-3 d-flex justify-content-between align-items-center"
                                >
                                    <span>
                                        <img
                                            class="me-2 rounded-circle"
                                            width="30px"
                                            :src="`${item.member.identification_photo}`"
                                            alt=""
                                        />
                                        {{ item.member.clean_name }}
                                    </span>
                                    <span>
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            :name="`options-base-${item.id}`"
                                            :id="`options-base-${item.id}-present`"
                                            autocomplete="off"
                                            v-model="item.status"
                                            value="COMPLETING"
                                        />

                                        <label
                                            class="btn fs-10 btn-outline-success me-2"
                                            :for="`options-base-${item.id}-present`"
                                        >
                                            Concluinte
                                        </label>

                                        <input
                                            type="radio"
                                            class="btn-check"
                                            :name="`options-base-${item.id}`"
                                            :id="`options-base-${item.id}-online`"
                                            autocomplete="off"
                                            v-model="item.status"
                                            value="PENDING"
                                        />
                                        <label
                                            class="btn fs-10 btn-outline-primary me-2"
                                            :for="`options-base-${item.id}-online`"
                                        >
                                            Com pendência
                                        </label>

                                        <input
                                            type="radio"
                                            class="btn-check"
                                            :name="`options-base-${item.id}`"
                                            :id="`options-base-${item.id}-not-completed`"
                                            autocomplete="off"
                                            v-model="item.status"
                                            value="NOT_COMPLETING"
                                        />
                                        <label
                                            class="btn fs-10 btn-outline-danger"
                                            :for="`options-base-${item.id}-not-completed`"
                                        >
                                            Não concluinte
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
