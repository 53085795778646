<script setup>
import { ref, onMounted, computed } from 'vue';
import draggable from 'vuedraggable';
import { useForm, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    discipleship: Object,
    classe: Object,
    disciplines: Object,
    disciplinesClasse: Object,
});

const form = useForm({
    start_time: props.classe.start_time_gross,
    end_time: props.classe.end_time_gross,
    report: props.classe.report,
});

const selected = ref([]);

onMounted(() => {
    selected.value = props.disciplinesClasse.map((discipline) => ({
        ...discipline.instructor_discipleship,
        start_time: discipline.start_time_gross,
    }));
});

const availableDisciplines = computed(() => {
    return props.disciplines.filter(
        (discipline) => !selected.value.some((selectedItem) => selectedItem.id === discipline.id)
    );
});

const transformSelected = (selectedItems) => {
    return selectedItems.map((item) => ({
        id: item.id,
        start_time: item.start_time,
    }));
};

const update = () =>
    form
        .transform((data) => ({
            ...data,
            disciplines: transformSelected(selected.value),
        }))
        .put(`/dashboard/discipleships/${props.discipleship.id}/modules/${props.classe.id}`);
</script>

<template>
    <Dashboard page-title="Editar módulo">
        <Breadcrumb
            current-page-name="Editar módulo"
            :link-list="[
                {
                    previous_page_url: `discipleships/${discipleship.id}/modules`,
                    previous_page_name: `${discipleship.name}`,
                },
            ]"
        />

        <div id="edit-classe">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar módulo</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/discipleships/${discipleship.id}/modules/${classe.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-6">
                    <label for="start_time" class="form-label"> Horário de início </label>

                    <input
                        id="start_time"
                        v-model="form.start_time"
                        type="datetime-local"
                        autocomplete="off"
                        class="form-control"
                        placeholder=""
                        required
                    />
                    <small v-if="form.errors.start_time" class="text-danger mt-3">
                        {{ form.errors.start_time }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="end_time" class="form-label"> Horário de fim </label>

                    <input
                        id="end_time"
                        v-model="form.end_time"
                        type="datetime-local"
                        autocomplete="off"
                        class="form-control"
                        placeholder=""
                        required
                    />
                    <small v-if="form.errors.end_time" class="text-danger mt-3">
                        {{ form.errors.end_time }}
                    </small>
                </div>

                <hr />

                <div class="row">
                    <div class="col-12 mb-3">
                        <legend class="mb-3">Disciplinas Disponíveis</legend>
                        <draggable
                            class="list-group"
                            :list="availableDisciplines"
                            group="people"
                            itemKey="name"
                        >
                            <template #item="{ element }">
                                <div
                                    class="list-group-item font-semibold fs-12 p-3"
                                    style="cursor: grabbing"
                                >
                                    <img
                                        class="me-2 rounded-circle"
                                        width="30px"
                                        :src="`${element.instructor_photo}`"
                                        alt=""
                                    />
                                    {{ element.instructor_name }} - {{ element.discipline_name }}
                                </div>
                            </template>
                        </draggable>
                    </div>

                    <hr />

                    <div class="col-12">
                        <legend class="mb-3">
                            {{ selected.length > 0 ? selected.length : '' }} Disciplinas
                            Selecionadas
                        </legend>
                        <draggable
                            class="list-group p-4"
                            style="border: 2px dashed #e5e5e5"
                            :list="selected"
                            group="people"
                            itemKey="name"
                        >
                            <template #item="{ element }">
                                <div
                                    class="list-group-item font-semibold fs-12 p-3 mb-2 border rounded-3 d-flex row"
                                    style="cursor: grabbing"
                                >
                                    <div class="col-12 col-sm-8">
                                        <img
                                            class="me-2 rounded-circle"
                                            width="30px"
                                            :src="`${element.instructor_photo}`"
                                            alt=""
                                        />
                                        {{ element.instructor_name }} -
                                        {{ element.discipline_name }}
                                    </div>
                                    <div class="col-12 col-sm-4 mt-3 mt-sm-0">
                                        <input
                                            v-model="element.start_time"
                                            id="start_time"
                                            type="datetime-local"
                                            autocomplete="off"
                                            class="form-control"
                                            placeholder=""
                                            required
                                        />
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-12">
                        <legend>Relatório geral do módulo</legend>

                        <div class="col-md-12">
                            <textarea
                                id="report"
                                v-model="form.report"
                                type="text"
                                class="form-control"
                                name="report"
                                placeholder="Forneça o relatório geral desse módulo"
                                rows="15"
                            />

                            <small v-if="form.errors.report" class="text-danger mt-3">
                                {{ form.errors.report }}
                            </small>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
