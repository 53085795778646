<script setup>
import { computed } from 'vue';
import { router, Link } from '@inertiajs/vue3';

import Layout from './Layout.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    department: Object,
    members: Object,
    isLeader: Boolean,
});

const leaders = computed(() => {
    return props.members.data.filter(
        (member) =>
            member.status !== 'OFF' &&
            (member.function == 'LEADER' ||
                member.function == 'VICE_LEADER' ||
                member.function == 'SUPERVISOR')
    );
});

const team = computed(() => {
    return props.members.data.filter(
        (member) => member.status !== 'OFF' && member.function == 'MEMBER'
    );
});

const disconnectedMembers = computed(() => {
    return props.members.data.filter((member) => member.status == 'OFF');
});
</script>

<template>
    <Layout :department="department">
        <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
            <div class="font-bold col-12 col-sm-6">{{ leaders.length + team.length }} membros</div>
            <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                <a
                    class="btn btn-danger fw-bold fs-12"
                    :href="`/dashboard/departments/members/pdf/export?department_id=${department.id}`"
                >
                    <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                </a>
                <a
                    class="btn btn-success ms-2 fw-bold fs-12"
                    :href="`/dashboard/departments/members/excel/export?department_id=${department.id}`"
                >
                    <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                </a>
            </div>
        </div>

        <hr class="mx-3" />

        <div class="row d-flex justify-content-center">
            <div
                class="col-md-4 col-sm-6 col-12 mb-3"
                v-for="(item, index) in leaders"
                :key="index"
            >
                <div class="card card-simple p-3">
                    <div class="text-end">
                        <Link
                            class="btn btn-light border"
                            :href="`/dashboard/departments/members/edit/${item.id}`"
                            :class="{ disabled: !isLeader }"
                            ><font-awesome-icon
                                class="text-body-secondary"
                                :icon="['fas', 'user-gear']"
                        /></Link>
                    </div>
                    <Link :href="`/dashboard/members/${item.member.id}`">
                        <img
                            class="mx-auto d-block rounded-circle"
                            :style="{
                                border: `4px solid ${department.identification_color}`,
                                width: '80px',
                            }"
                            :src="`${item.member.identification_photo}`"
                            alt=""
                        />
                    </Link>
                    <h6 class="text-center mt-2 font-bold">
                        {{ item.member.clean_name }}
                    </h6>
                    <span class="mt-2 text-center">
                        <Badge :name="item.function" />
                    </span>
                </div>
            </div>
            <div class="col-md-4 col-sm-6 col-12 mb-3" v-for="(item, index) in team" :key="index">
                <div
                    class="card card-simple p-3"
                    :class="{
                        'border border-info bg-info-subtle': item.status === 'INACTIVE',
                    }"
                >
                    <div class="text-end">
                        <Link
                            class="btn btn-light border"
                            :href="`/dashboard/departments/members/edit/${item.id}`"
                            :class="{ disabled: !isLeader }"
                            ><font-awesome-icon
                                class="text-body-secondary"
                                :icon="['fas', 'user-gear']"
                        /></Link>
                    </div>
                    <Link :href="`/dashboard/members/${item.member.id}`">
                        <img
                            class="mx-auto d-block rounded-circle"
                            :style="{
                                border: `4px solid ${department.identification_color}`,
                                width: '80px',
                            }"
                            :src="`${item.member.identification_photo}`"
                            alt=""
                        />
                    </Link>
                    <h6 class="text-center mt-2 font-bold">
                        {{ item.member.clean_name }}
                    </h6>
                    <span class="mt-2 text-center">
                        <Badge :name="item.function" />
                    </span>
                </div>
            </div>

            <div
                class="accordion"
                id="accordionDisconnectedMembers"
                v-if="disconnectedMembers.length"
            >
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                        >
                            Membros que já serviram nesse departamento
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordionDisconnectedMembers"
                    >
                        <div class="accordion-body">
                            <div class="row d-flex justify-content-center">
                                <div
                                    class="col-md-4 col-sm-6 col-12 mb-3"
                                    v-for="(item, index) in disconnectedMembers"
                                    :key="index"
                                >
                                    <div
                                        class="card card-simple p-3"
                                        :class="{
                                            'border border-info bg-info-subtle':
                                                item.status === 'INACTIVE',
                                        }"
                                    >
                                        <div class="text-end">
                                            <Link
                                                class="btn btn-light border"
                                                :href="`/dashboard/departments/members/edit/${item.id}`"
                                                :class="{ disabled: !isLeader }"
                                                ><font-awesome-icon
                                                    class="text-body-secondary"
                                                    :icon="['fas', 'user-gear']"
                                            /></Link>
                                        </div>
                                        <Link :href="`/dashboard/members/${item.member.id}`">
                                            <img
                                                class="mx-auto d-block rounded-circle"
                                                :style="{
                                                    border: `4px solid ${department.identification_color}`,
                                                    width: '80px',
                                                }"
                                                :src="`${item.member.identification_photo}`"
                                                alt=""
                                            />
                                        </Link>
                                        <h6 class="text-center mt-2 font-bold">
                                            {{ item.member.clean_name }}
                                        </h6>
                                        <span class="mt-2 text-center">
                                            <Badge :name="item.status" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
