<script setup>
import { ref } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    member: Object,
});

const memberEditModalCloseButton = ref(null);

const destroy = () => {
    memberEditModalCloseButton.value.click();
    router.delete(
        `/dashboard/discipleships/${props.member.discipleship_id}/members/${props.member.id}`
    );
};
</script>

<template>
    <Dashboard :page-title="member.member.clean_name">
        <Breadcrumb
            :current-page-name="member.member.clean_name"
            :link-list="[
                {
                    previous_page_url: `discipleships/${member.discipleship_id}/members`,
                    previous_page_name: `Inscritos`,
                },
            ]"
        />

        <div class="row mt-3 mb-3">
            <div class="col-md-8 col-12">
                <h4 class="mb-0">Situação do inscrito</h4>
            </div>
            <div class="col-md-4 col-12 mt-3 mt-sm-0 text-end">
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#memberEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Remover inscrição
                </a>
            </div>
        </div>

        <div class="mb-3 mt-3 mt-sm-0">
            <div id="discipline-details" class="row mb-3 px-3">
                <div class="d-flex justify-content-center">
                    <img
                        class="identification_photo shadow-sm"
                        :src="`${member.member.identification_photo}`"
                        alt=""
                    />
                </div>
                <h4 class="text-center mt-4">
                    {{ member.member.clean_name }}
                </h4>
            </div>
            <div class="row" v-if="member.frequencies.length">
                <hr />
                <div
                    class="col-12 col-sm-6 col-md-4 mb-3"
                    v-for="(frequency, index) in member.frequencies"
                    :key="index"
                >
                    <div class="card p-3">
                        <div class="card-title font-bold pb-2" style="height: 40px">
                            #{{ 1 + index }} - {{ frequency.discipline_name }}
                        </div>
                        <div class="card-body p-3">
                            <div class="row">
                                <div
                                    class="alert mx-0 font-semibold fs-12 alert-primary"
                                    role="alert"
                                    v-if="frequency.status == 'COMPLETED_REMOTELY'"
                                >
                                    Concluído online
                                </div>
                                <div
                                    class="alert mx-0 font-semibold fs-12 alert-success"
                                    role="alert"
                                    v-if="frequency.status == 'COMPLETED'"
                                >
                                    Concluído Presencialmente
                                </div>
                                <div
                                    class="alert mx-0 font-semibold fs-12 alert-warning"
                                    role="alert"
                                    v-if="frequency.status == 'NOT_COMPLETED'"
                                >
                                    Ausente
                                </div>
                                <div
                                    class="alert mx-0 font-semibold fs-12 alert-info"
                                    role="alert"
                                    v-if="frequency.status == 'PENDING'"
                                >
                                    Pendente
                                </div>
                            </div>

                            <div class="row d-flex justify-content-end">
                                <Link
                                    :href="`/dashboard/discipleships/${member.discipleship_id}/modules/${frequency.module_id}/classes/${frequency.classe_id}`"
                                    class="btn btn-primary btn-add"
                                    >Acessar aula</Link
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                modal_id="memberEditModal"
                modal_title="Confirmação de remoção do registro"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir o registro?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="memberEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar registro
                    </button>
                </template>
            </ConfirmationModal>
        </div>
    </Dashboard>
</template>
