<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

import useDateFormat from '@/Pages/Dashboard/Composables/useDateFormat.vue';

const { simplifyDate } = useDateFormat();

const props = defineProps({
    allEvents: Object,
    counselors: Object,
    typesNewBirths: Object,
    maritalStatus: Object,
    spouses: Object,
    newBirth: Object,
    currentEvent: Object,
    groupMeetingsConnections: Object,
});

const mounted = ref(false);

const form = useForm({
    event_id: props.currentEvent.id,
    type: props.newBirth?.type,
    marital_status: props.newBirth.member.marital_status,
    observations: props.newBirth.observations,
    counselors: [],
    number: props.newBirth.member.first_address?.number,
    address: props.newBirth.member.first_address?.address,
    neighborhood: props.newBirth.member.first_address?.neighborhood,
    spouse_id: null,
    spouse_name: null,
    group_connection_meeting_id: props.newBirth.connection_group_meeting
        ? props.newBirth.connection_group_meeting.id
        : null,
});

const seacher = reactive({
    spouse_name: null,
});

const search = () => {
    router.visit(`/dashboard/new-births/edit/${props.newBirth.id}`, {
        method: 'get',
        data: { seacher },
        only: ['spouses'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.spouse_id = props.spouses ? form.spouse_id : null;
        },
    });
};

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const update = () =>
    form
        .transform((data) => ({
            ...data,
            spouse_name: form.marital_status == 'MARRIED' ? form.spouse_name : '',
            spouse_id: form.marital_status == 'MARRIED' ? form.spouse_id : '',
        }))
        .put(`/dashboard/new-births/${props.newBirth.id}`);

const member = ref(false);

const toggleCounselorSelection = (counselorId) => {
    const index = form.counselors.indexOf(counselorId);
    return index === -1 ? form.counselors.push(counselorId) : form.counselors.splice(index, 1);
};

const toggleSelection = (field, id) => {
    if (form[field] === id) {
        form[field] = null;
    } else {
        form[field] = id;
    }
};

const clearQueryParams = () => router.replace(`/dashboard/new-births/edit/${props.newBirth.id}`);

onMounted(() => {
    clearQueryParams();
    setInterval(() => (mounted.value = true), 2000);

    member.value = props.newBirth.member;
    form.counselors = props.newBirth.counselors.map((counselor) => counselor.id);

    if (props.newBirth.member.marital_status == 'MARRIED') {
        let spouse = props.newBirth.member.spouse;
        seacher.spouse_name = spouse.name;
        form.spouse_name = spouse.name;
        form.spouse_id = spouse.member_id;
        search();
    }

    if (member.value.families?.[0]?.addresses?.[0]) {
        const address = member.value.families[0].addresses[0];
        Object.assign(form, {
            address: address.address,
            neighborhood: address.neighborhood,
            number: address.number,
        });
    }
});

const newBirthEditModalCloseButton = ref(null);

const destroy = () => {
    newBirthEditModalCloseButton.value.click();
    router.delete(`/dashboard/new-births/${props.newBirth.id}`);
};
</script>

<template>
    <Dashboard page-title="Novo nascimento">
        <Breadcrumb
            current-page-name="Novo nascimento"
            :link-list="[
                {
                    previous_page_url: 'new-births',
                    previous_page_name: 'Novos nascimentos',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/new-births/${newBirth.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#newBirthEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <template v-if="mounted && members?.length">
                <hr />

                <legend class="m-0">Membro relacionado</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in members"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.member_id === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.member_id === item.id"
                            @change="toggleSelection('member_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <Link class="btn btn-primary btn-add z-2 me-3">Acessar perfil</Link>
                            </div>
                        </label>
                    </li>
                </ul>
                <hr class="my-0" />
            </template>

            <div class="col-md-6">
                <label for="address" class="form-label">Endereço</label>
                <input
                    id="address"
                    v-model="form.address"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Rua São Jorge"
                    required
                />
                <small v-if="form.errors.address" class="text-danger mt-3">
                    {{ form.errors.address }}
                </small>
            </div>

            <div class="col-md-3">
                <label for="number" class="form-label">Número</label>
                <input
                    id="number"
                    v-model="form.number"
                    type="number"
                    autocomplete="off"
                    class="form-control"
                    placeholder="120"
                />
                <small v-if="form.errors.number" class="text-danger mt-3">
                    {{ form.errors.number }}
                </small>
            </div>

            <div class="col-md-3">
                <label for="neighborhood" class="form-label">Bairro</label>
                <input
                    id="neighborhood"
                    v-model="form.neighborhood"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Centro"
                />
                <small v-if="form.errors.neighborhood" class="text-danger mt-3">
                    {{ form.errors.neighborhood }}
                </small>
            </div>

            <div class="col-md-6">
                <label for="type" class="form-label">Evento relacionado</label>
                <select id="type" v-model="form.event_id" class="form-select" required>
                    <option v-for="(item, index) in allEvents" :key="index" :value="item.id">
                        {{ item.schedule.name }} - {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-3">
                <label for="type" class="form-label">Estado civil</label>
                <select id="type" v-model="form.marital_status" class="form-select">
                    <option v-for="(item, index) in maritalStatus" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-3">
                <label for="type" class="form-label">Tipo</label>
                <select id="type" v-model="form.type" class="form-select">
                    <option
                        v-for="(item, index) in typesNewBirths"
                        :key="index"
                        :value="item.value"
                    >
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-12">
                <label for="observations" class="form-label"> Observações </label>

                <textarea
                    id="observations"
                    v-model="form.observations"
                    type="text"
                    class="form-control"
                    name="observations"
                    placeholder="Caso necessário, forneca alguma observação para esse registro"
                    rows="5"
                />

                <small v-if="form.errors.observations" class="text-danger mt-3">
                    {{ form.errors.observations }}
                </small>
            </div>

            <div v-if="form.marital_status == 'MARRIED'" class="col-md-12">
                <label for="spouse_id" class="form-label">Nome Cônjuge</label>
                <input
                    id="spouse_id"
                    v-model="form.spouse_name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder=""
                    @input="compositionSeacher('spouse_name', $event)"
                />
                <small v-if="form.errors.spouse_name" class="text-danger mt-3">
                    {{ form.errors.spouse_name }}
                </small>
            </div>

            <template v-if="mounted && spouses?.length && form.marital_status == 'MARRIED'">
                <hr />

                <legend class="m-0">Cônjuge relacionado</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in spouses"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.spouse_id === item.id }"
                    >
                        <input
                            :id="'checkBoxSpouse' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.spouse_id === item.id"
                            @change="toggleSelection('spouse_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxSpouse' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="`/dashboard/members/${item.id}`"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar perfil</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>
            </template>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione os conselheiros</legend>

            <div class="row">
                <div
                    v-for="(item, index) in counselors"
                    :key="index"
                    class="col-md-2 col-6 text-center mb-3 mb-sm-0"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`/storage/${item.identification_photo}`"
                            :class="{
                                selected: form.counselors.includes(item.id),
                            }"
                            @click="toggleCounselorSelection(item.id)"
                        />
                        <span
                            v-show="form.counselors.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>

            <template v-if="groupMeetingsConnections.length">
                <hr />
                <legend class="m-0">Encontro de Gc relacionado</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in groupMeetingsConnections"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.group_connection_meeting_id === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.group_connection_meeting_id === item.id"
                            @change="toggleSelection('group_connection_meeting_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span
                                    >{{ item.group_connection_name }} -
                                    {{ simplifyDate(item.meeting_date) }}</span
                                >
                                <a
                                    :href="`/dashboard/group-connections/${item.group_connection_id}`"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar grupo</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>
            </template>
        </Form>

        <ConfirmationModal
            modal_id="newBirthEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="newBirthEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
