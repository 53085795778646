<script setup>
import { router, Link } from '@inertiajs/vue3';

import Layout from '../Layout.vue';

const props = defineProps({
    discipleship: Object,
    disciplines: Object,
});

const goToDisciplinePage = (disciplineID) =>
    router.get(`/dashboard/discipleships/${props.discipleship.id}/disciplines/${disciplineID}`);
</script>

<template>
    <Layout :discipleship="discipleship">
        <div
            id="discipline-tab-pane"
            class="tab-pane show active fade card p-4"
            role="tabpanel"
            aria-labelledby="contact-tab-pane"
            tabindex="0"
        >
            <div v-if="disciplines.length">
                <div class="d-flex justify-content-between mb-2 align-items-center">
                    <h5 class="font-bold mb-0">Disciplinas</h5>
                    <Link
                        class="btn btn-primary btn-redirection"
                        :href="`/dashboard/discipleships/${discipleship.id}/disciplines/create`"
                    >
                        Nova
                    </Link>
                </div>
                <hr />
                <div class="table-responsive search-form-card p-0">
                    <table class="table m-0 align-middle table-hover" id="table-families">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">#</th>
                                <th scope="col">Disciplina</th>
                                <th scope="col">Ministro Responsável</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(member, index) in disciplines"
                                :key="index"
                                @click="goToDisciplinePage(member.id)"
                            >
                                <td class="text-center">
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    {{ member.discipline_name }}
                                </td>
                                <td>
                                    <img
                                        class="rounded-circle me-3"
                                        width="30"
                                        :src="member.instructor_photo"
                                        alt=""
                                    />
                                    <span>
                                        {{ member.instructor_name }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else>
                <p
                    class="p-3 bg-info bg-opacity-10 fs-12 rounded-end font-semibold border-primary border-start border-5"
                >
                    Nenhuma disciplina adicionada
                </p>

                <div class="d-flex justify-content-end">
                    <Link
                        class="btn btn-primary btn-redirection"
                        :href="`/dashboard/discipleships/${discipleship.id}/disciplines/create`"
                    >
                        Adicionar
                    </Link>
                </div>
            </div>
        </div>
    </Layout>
</template>
