<script setup>
import { computed } from 'vue';
import { Link, router } from '@inertiajs/vue3';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import CreatorRecord from '@/Pages/Dashboard/Shared/CreatorRecord.vue';

const props = defineProps({
    music: Object,
    musicArtist: Object,
    recommendedSongs: Object,
    creatorRegistration: Object,
});

const goToMusicPage = (musicId) => router.get(`/dashboard/musics/${musicId}`);

const convertYouTubeLink = (link) => {
    if (link && link.includes('/watch?v=')) {
        link = link.replace('/watch?v=', '/embed/');
        link = link.split('&')[0];
    }
    return link;
};
</script>

<template>
    <Dashboard :page-title="music.name">
        <div id="show-music">
            <Breadcrumb
                :current-page-name="music.name"
                :link-list="[
                    {
                        previous_page_url: 'musics',
                        previous_page_name: 'Músicas',
                    },
                ]"
            />
            <div class="card-simple card p-4">
                <div class="row d-flex align-items-center">
                    <div class="col-lg-3">
                        <div class="position-relative d-flex justify-content-center">
                            <img
                                class="rounded-3 shadow"
                                width="200px"
                                :src="`/storage/${music.cover_photo}`"
                                alt=""
                            />
                            <div class="position-absolute top-0 end-0 m-3 me-5 me-sm-3">
                                <Link
                                    :href="`/dashboard/musics/edit/${music.id}`"
                                    class="btn btn-light border"
                                    ><font-awesome-icon :icon="['fas', 'pen-to-square']"
                                /></Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 mt-3 mt-sm-0">
                        <div class="row">
                            <div class="col">
                                <span class="font-semibold text-secondary">Música</span>
                                <h2 class="font-bold">{{ music.name }}</h2>
                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="d-flex align-items-center">
                                        <Link :href="`/dashboard/artists/${music.artist_id}`">
                                            <img
                                                class="rounded-circle mt-2"
                                                width="30px"
                                                :src="`/storage/${music.artist.profile_photo}`"
                                                alt=""
                                            />
                                        </Link>
                                        <span class="text-secondary font-semibold ms-2 mt-2">{{
                                            music.artist.name
                                        }}</span>
                                    </span>
                                    <Link
                                        v-if="music.release_year"
                                        href="#"
                                        class="btn btn-secondary btn-sm ms-2 font-semibold disabled mt-2"
                                    >
                                        {{ music.release_year }}
                                    </Link>

                                    <Link
                                        v-for="(item, index) in music.musical_themes"
                                        :key="index"
                                        :href="`/dashboard/musical-themes/${item.id}`"
                                        class="btn btn-warning btn-sm ms-2 font-semibold mt-2"
                                    >
                                        {{ item.name }}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div v-if="music.reproductions" class="row mt-3 font-semibold ms-1">
                            Já tocada {{ music.reproductions }} veze(s) na igreja
                        </div>
                    </div>
                </div>
                <hr />
                <h3 class="font-bold mb-3">Letra</h3>
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link active font-semibold fs-12"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'circle-check']" />
                            Versão Oficial
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link font-semibold fs-12"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            :disabled="music.ministry_letter == null"
                            aria-controls="pills-profile"
                            aria-selected="false"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'house-circle-check']" />
                            Versão Ministerial
                        </button>
                    </li>
                </ul>

                <div class="row">
                    <div class="col-lg-6 col-12">
                        <div class="tab-content" id="pills-tabContent">
                            <div
                                class="tab-pane fade show active"
                                id="pills-home"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                                tabindex="0"
                            >
                                <textarea
                                    id="ministry_letter"
                                    :value="music.official_letter"
                                    type="text"
                                    disabled
                                    class="form-control"
                                    name="ministry_letter"
                                    rows="20"
                                />
                            </div>
                            <div
                                class="tab-pane fade"
                                id="pills-profile"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                                tabindex="0"
                            >
                                <textarea
                                    id="ministry_letter"
                                    :value="music.ministry_letter"
                                    type="text"
                                    disabled
                                    class="form-control"
                                    name="ministry_letter"
                                    rows="20"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-4 mt-md-0">
                        <div class="ratio ratio-16x9 ratio-xxl-21x9 bg-secondary rounded-3">
                            <iframe
                                v-if="music.youtube_link"
                                class="rounded-3 shadow"
                                frameborder="0"
                                :src="`${convertYouTubeLink(music.youtube_link)}`"
                            />
                        </div>
                        <a
                            class="btn btn-secondary fs-12 mt-4 w-100 font-semibold"
                            target="_blank"
                            :class="{ disabled: !music.link_lyrics }"
                            :href="music.link_lyrics"
                            role="button"
                        >
                            <font-awesome-icon class="me-2" :icon="['far', 'circle-play']" /> Letra
                            detalhada da música
                        </a>
                    </div>
                </div>

                <hr />

                <template v-if="recommendedSongs.length">
                    <h4 class="font-bold font-bold mb-0">Recomendações</h4>
                    <span class="text-secondary font-semibold fs-12 mb-3"
                        >Com base nesta música</span
                    >
                    <div class="table-responsive search-form-card">
                        <table class="table m-0 align-middle table-hover" id="table-musics">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th class="text-center" scope="col">Youtube</th>
                                    <th class="text-center" scope="col">Duração</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(music, musicIndex) in recommendedSongs"
                                    :key="musicIndex"
                                    @click="goToMusicPage(music.id)"
                                >
                                    <td class="text-center">
                                        {{ musicIndex + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-pill me-3"
                                            height="35"
                                            width="35"
                                            :src="`/storage/${music.cover_photo}`"
                                            alt=""
                                        />
                                        <span>
                                            {{ music.name }}
                                        </span>
                                    </td>

                                    <td class="text-center">
                                        <a
                                            target="_blank"
                                            class="btn btn-link text-danger p-0 fs-3"
                                            :class="{ disabled: !music.youtube_link }"
                                            :href="music.youtube_link"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            data-bs-title="Assistir no Youtube"
                                        >
                                            <font-awesome-icon :icon="['fab', 'youtube']" />
                                        </a>
                                    </td>

                                    <td class="text-center">{{ music.duration }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr />
                </template>

                <template v-if="musicArtist.length">
                    <span class="text-secondary font-semibold fs-12">Músicas disponíveis de </span>
                    <h4 class="font-bold font-bold mb-3">{{ music.artist.name }}</h4>

                    <div class="table-responsive search-form-card">
                        <table class="table m-0 align-middle table-hover" id="table-musics">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th class="text-center" scope="col">Youtube</th>
                                    <th class="text-center" scope="col">Duração</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(music, musicIndex) in musicArtist"
                                    :key="musicIndex"
                                    @click="goToMusicPage(music.id)"
                                >
                                    <td class="text-center">
                                        {{ musicIndex + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-pill me-3"
                                            height="35"
                                            width="35"
                                            :src="`/storage/${music.cover_photo}`"
                                            alt=""
                                        />
                                        <span>
                                            {{ music.name }}
                                        </span>
                                    </td>

                                    <td class="text-center">
                                        <a
                                            target="_blank"
                                            class="btn btn-link text-danger p-0 fs-3"
                                            :class="{ disabled: !music.youtube_link }"
                                            :href="music.youtube_link"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            data-bs-title="Assistir no Youtube"
                                        >
                                            <font-awesome-icon :icon="['fab', 'youtube']" />
                                        </a>
                                    </td>

                                    <td class="text-center">{{ music.duration }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
            <CreatorRecord
                v-if="creatorRegistration.name"
                :data="creatorRegistration"
                title="Música cadastrada por: "
            />
        </div>
    </Dashboard>
</template>
