<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    counselors: Object,
});

const goToCounselorPage = (counselorId) => router.get(`/dashboard/counselors/${counselorId}`);

const clearQueryParams = () => router.replace('/dashboard/counselors');

const searchForm = reactive({
    name: null,
    order_by: 'asc',
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/counselors', searchForm, {
        preserveState: true,
    });

const order = () => {
    searchForm.order_by = searchForm.order_by === 'asc' ? 'desc' : 'asc';
    seacher();
};

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Conselheiros">
        <Breadcrumb current-page-name="Conselheiros" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Conselheiros</h4>
                <Link :href="`/dashboard/counselors/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row align-items-center p-3">
                    <div class="col-lg-6 px-0">
                        <div class="input-group">
                            <span id="input-group-name" class="input-group-text bg-transparent">
                                <font-awesome-icon
                                    class="text-secondary"
                                    :icon="['fas', 'magnifying-glass']"
                                />
                            </span>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Buscar pelo nome"
                                aria-label="Buscar pelo nome"
                                aria-describedby="input-group-name"
                                @input="compositionSeacher('name', $event)"
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 px-0 mt-2 mt-sm-0">
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-light border" @click="order()">
                                <font-awesome-icon
                                    v-if="searchForm.order_by == 'asc'"
                                    class="me-2"
                                    :icon="['fas', 'arrow-down-a-z']"
                                />
                                <font-awesome-icon
                                    v-else
                                    class="me-2"
                                    :icon="['fas', 'arrow-down-z-a']"
                                />
                                Ordernar
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row p-3">
                    <div class="table-responsive">
                        <table class="table m-0 align-middle table-hover" id="table-counselors">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome do conselheiro</th>
                                    <th class="text-center" scope="col">Data de cadastro</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(counselor, index) in counselors.data"
                                    :key="index"
                                    @click="goToCounselorPage(counselor.id)"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-circle me-3"
                                            width="30"
                                            :src="`/storage/${counselor.identification_photo}`"
                                            alt=""
                                        />
                                        <span>
                                            {{ counselor.name }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        {{ counselor.created_at }}
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="4">
                                        <Pagination
                                            :data="counselors.meta"
                                            reference-date="counselors"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
