<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

// Components
import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    family: Object,
    memberSection: String,
});

const adjustDegreeFamiliarity = (degreeFamiliarity, sex) => {
    if (degreeFamiliarity === 'SPOUSE') {
        return sex === 'MALE' ? 'Esposo' : 'Esposa';
    } else if (degreeFamiliarity === 'CHILD') {
        return sex === 'MALE' ? 'Filho' : 'Filha';
    } else {
        return 'Outro';
    }
};

const spousesAndParents = computed(() => {
    return props.family.members
        .map((member) => ({
            ...member,
            degree_familiarity: adjustDegreeFamiliarity(member.degree_familiarity, member.sex),
        }))
        .filter(
            (member) =>
                member.degree_familiarity === 'Esposo' || member.degree_familiarity === 'Esposa'
        );
});

const childrenAndOthers = computed(() => {
    return props.family.members
        .map((member) => ({
            ...member,
            degree_familiarity: adjustDegreeFamiliarity(member.degree_familiarity, member.sex),
        }))
        .filter(
            (member) =>
                member.degree_familiarity === 'Filho' ||
                member.degree_familiarity === 'Filha' ||
                member.degree_familiarity === 'Outro'
        );
});
</script>

<template>
    <div class="card my-3 bg-transparent" style="border-style: dashed" v-if="family.members.length">
        <div class="d-flex justify-content-between align-items-center p-4" v-if="memberSection">
            <h4 class="font-bold mb-0">Membros</h4>
            <Link :href="`/dashboard/families/edit/${family.id}`" class="btn btn-primary btn-add">
                <font-awesome-icon class="me-2" :icon="['fas', 'gear']" />
                Gerenciar
            </Link>
        </div>

        <h4 v-else class="font-bold my-sm-4 my-3 d-block mx-auto">Membros</h4>

        <div class="card-body">
            <div class="row" v-if="spousesAndParents.length">
                <div
                    class="col-12 col-sm-6 mb-3 mb-sm-0"
                    v-for="(item, index) in spousesAndParents"
                    :key="index"
                >
                    <div class="card p-3" :class="{ 'border border-danger': !item.church_member }">
                        <span class="text-end" v-if="item.church_member">
                            <font-awesome-icon
                                class="text-success fs-2"
                                :icon="['fas', 'circle-check']"
                            />
                        </span>
                        <span v-else class="text-end">
                            <font-awesome-icon
                                class="text-white fs-2"
                                :icon="['fas', 'circle-check']"
                            />
                        </span>

                        <div>
                            <Link
                                v-if="item.member_id"
                                :href="`/dashboard/members/${item.member_id}`"
                            >
                                <img
                                    class="mx-auto d-block rounded-circle"
                                    style="width: 80px"
                                    :src="`${item.identification_photo}`"
                                    alt=""
                                />
                            </Link>
                            <div v-else class="disabled">
                                <img
                                    class="mx-auto d-block rounded-circle"
                                    style="width: 80px"
                                    :src="`${item.identification_photo}`"
                                    alt=""
                                />
                            </div>
                        </div>
                        <h6 class="text-center mt-2 font-bold">{{ item.name }}</h6>
                        <span class="mt-2 text-center">
                            <Badge :name="item.degree_familiarity" />
                        </span>
                    </div>
                </div>
            </div>

            <hr v-if="childrenAndOthers.length && spousesAndParents.length" />

            <!-- Children and Others Section -->
            <div class="row d-flex justify-content-center" v-if="childrenAndOthers.length">
                <div
                    class="col-12 col-sm-6 mb-3"
                    v-for="(item, index) in childrenAndOthers"
                    :key="index"
                >
                    <div class="card p-3" :class="{ 'border border-danger': !item.church_member }">
                        <span class="text-end" v-if="item.church_member">
                            <font-awesome-icon
                                class="text-success fs-2"
                                :icon="['fas', 'circle-check']"
                            />
                        </span>
                        <span v-else class="text-end">
                            <font-awesome-icon
                                class="text-white fs-2"
                                :icon="['fas', 'circle-check']"
                            />
                        </span>
                        <div>
                            <Link
                                v-if="item.member_id"
                                :href="`/dashboard/members/${item.member_id}`"
                            >
                                <img
                                    class="mx-auto d-block rounded-circle"
                                    style="width: 80px"
                                    :src="`${item.identification_photo}`"
                                    alt=""
                                />
                            </Link>
                            <div v-else class="disabled">
                                <img
                                    class="mx-auto d-block rounded-circle"
                                    style="width: 80px"
                                    :src="`${item.identification_photo}`"
                                    alt=""
                                />
                            </div>
                        </div>
                        <h6 class="text-center mt-2 font-bold">{{ item.name }}</h6>
                        <span class="mt-2 text-center">
                            <Badge :name="item.degree_familiarity" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="card my-3 bg-transparent"
        style="border-style: dashed"
        v-if="family?.addresses.length"
    >
        <h4 class="font-bold text-center mt-4">Endereço(s) da família</h4>
        <div class="card-body">
            <div class="card p-4 mb-3" v-for="(address, index) in family.addresses" :key="index">
                <form>
                    <fieldset disabled>
                        <div class="row">
                            <!-- Endereço -->
                            <div class="col-md-8">
                                <label for="address" class="form-label"> Endereço </label>
                                <input
                                    :id="'address-' + index"
                                    :value="address.address"
                                    type="text"
                                    autocomplete="off"
                                    class="form-control"
                                    placeholder="Nome da rua"
                                />
                            </div>

                            <div class="col-md-4 mt-2 mt-sm-0">
                                <label for="zipcode" class="form-label"> CEP </label>
                                <input
                                    v-maska
                                    :id="'zipcode-' + index"
                                    :value="address.zipcode"
                                    type="text"
                                    autocomplete="off"
                                    class="form-control"
                                    placeholder="Número do CEP"
                                    data-maska="#####-###"
                                    @blur="fetchAddressByZipcode(index)"
                                />
                            </div>

                            <div class="col-md-4 mt-3">
                                <label for="neighborhood" class="form-label"> Bairro </label>
                                <input
                                    :id="'neighborhood-' + index"
                                    :value="address.neighborhood"
                                    type="text"
                                    autocomplete="off"
                                    class="form-control"
                                    placeholder="Nome do bairro"
                                />
                            </div>

                            <div class="col-md-4 mt-3">
                                <label for="city" class="form-label"> Cidade </label>
                                <input
                                    :id="'city-' + index"
                                    :value="address.city"
                                    type="text"
                                    autocomplete="off"
                                    class="form-control"
                                    placeholder="Nome da cidade"
                                />
                            </div>

                            <div class="col-md-4 mt-3">
                                <label for="number" class="form-label"> Número </label>
                                <input
                                    :id="'number-' + index"
                                    :value="address.number"
                                    type="text"
                                    autocomplete="off"
                                    class="form-control"
                                    placeholder="Número da casa"
                                />
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</template>
