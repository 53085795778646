<script setup>
import { ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    child: Object,
    status: Object,
    events: Object,
});

const form = useForm({
    father_name: props.child.father_name,
    mother_name: props.child.mother_name,
    child_name: props.child.child_name,
    child_age: props.child.child_age,
    observations: props.child.observations,
    scheduled_date: props.child.scheduled_date_gross,
    status: props.child.status,
    event_id: props.child.event_id || props.events[0]?.id,
});

const update = () => form.put(`/dashboard/children-consecration/${props.child.id}`);

const childEditModalCloseButton = ref(null);

const destroy = () => {
    childEditModalCloseButton.value.click();
    router.delete(`/dashboard/children-consecration/${props.child.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar">
        <Breadcrumb
            current-page-name="Editar"
            :link-list="[
                {
                    previous_page_url: `children-consecration/${child.id}`,
                    previous_page_name: 'Consagrações',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/children-consecration/${child.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#childEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Editar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="row g-3">
                <div class="col-sm-6">
                    <label for="father_name" class="form-label">Nome do pai</label>
                    <small class="optional ms-2">(opcional)</small>
                    <input
                        placeholder="João Pedro de Souza"
                        type="text"
                        v-model="form.father_name"
                        class="form-control"
                        name="father_name"
                        id="father_name"
                        autocomplete="on"
                        aria-describedby="father_nameHelp"
                        required
                    />
                    <small v-if="form.errors.father_name" class="text-danger mt-3">
                        {{ form.errors.father_name }}
                    </small>
                </div>

                <div class="col-sm-6">
                    <label for="mother_name" class="form-label"> Nome da mãe </label>
                    <small class="optional ms-2">(opcional)</small>
                    <input
                        placeholder="Maria Lima de Souza"
                        id="mother_name"
                        v-model="form.mother_name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.mother_name" class="text-danger mt-3">
                        {{ form.errors.mother_name }}
                    </small>
                </div>

                <div class="col-sm-6">
                    <label for="child_name" class="form-label">Nome da criança</label>
                    <small class="optional ms-2">(opcional)</small>
                    <input
                        placeholder="Miguel Lima Santos"
                        type="text"
                        v-model="form.child_name"
                        class="form-control"
                        name="child_name"
                        id="child_name"
                        autocomplete="on"
                        aria-describedby="child_nameHelp"
                    />
                    <small v-if="form.errors.child_name" class="text-danger mt-3">
                        {{ form.errors.child_name }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="type" class="form-label">Evento relacionado</label>
                    <select id="type" v-model="form.event_id" class="form-select">
                        <option v-for="(item, index) in events" :key="index" :value="item.id">
                            {{ item.schedule.name }} - {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-sm-4">
                    <label for="child_age" class="form-label">Idade da criança</label>
                    <input
                        placeholder="6 meses"
                        type="text"
                        v-model="form.child_age"
                        class="form-control"
                        name="child_age"
                        id="child_age"
                        autocomplete="on"
                        aria-describedby="child_ageHelp"
                    />
                    <small v-if="form.errors.child_age" class="text-danger mt-3">
                        {{ form.errors.child_age }}
                    </small>
                </div>
                <div class="col-sm-4">
                    <label for="scheduled_date" class="form-label">Data da consagração</label>
                    <input
                        placeholder="6 meses"
                        type="date"
                        v-model="form.scheduled_date"
                        class="form-control"
                        name="scheduled_date"
                        id="scheduled_date"
                        autocomplete="on"
                        aria-describedby="scheduled_dateHelp"
                    />
                    <small v-if="form.errors.scheduled_date" class="text-danger mt-3">
                        {{ form.errors.scheduled_date }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="type" class="form-label">Status</label>
                    <select
                        id="type"
                        v-model="form.status"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in status" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-12">
                    <label for="observations" class="form-label"> Observações </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="observations"
                        v-model="form.observations"
                        type="text"
                        class="form-control"
                        name="observations"
                        placeholder="Forneça alguma informação importante dessa consagração"
                        rows="5"
                    />

                    <small v-if="form.errors.observations" class="text-danger mt-3">
                        {{ form.errors.observations }}
                    </small>
                </div>
            </div>
        </Form>
        <ConfirmationModal
            modal_id="childEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="childEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
