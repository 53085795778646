<script setup>
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    group: Object,
    meeting: Object,
    members: Object,
});

const goToMemberPage = (memberID) =>
    router.get(`/dashboard/group-connections/${props.group.id}/members/${memberID}`);

const goToMemberVisitPage = (visitorID) => router.get(`/dashboard/visitors/${visitorID}`);

const goToMemberNewBirthPage = (newBirthID) => router.get(`/dashboard/new-births/${newBirthID}`);
</script>

<template>
    <Dashboard :page-title="group.name">
        <Breadcrumb
            :current-page-name="group.name"
            :link-list="[
                {
                    previous_page_url: `group-connections/${group.id}/meetings`,
                    previous_page_name: 'Encontros',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Encontro do GC</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <button
                    type="button"
                    class="btn btn-success me-2 font-semibold"
                    :class="meeting.active ? 'btn-success' : 'btn-danger'"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :data-bs-title="`${meeting.active ? 'Ativo' : 'Encerrado'}`"
                >
                    <font-awesome-icon :icon="['fas', meeting.active ? 'circle-check' : 'ban']" />
                </button>

                <Link
                    :href="`/dashboard/group-connections/${group.id}/meetings/edit/${meeting.id}`"
                    class="btn btn-add btn-primary"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                    Editar dados
                </Link>
            </div>
        </div>

        <hr v-if="members.length" />

        <form action="">
            <fieldset class="row" disabled="disabled">
                <div class="col-md-4">
                    <label for="meeting_date" class="form-label"> Horário do encontro </label>

                    <input
                        id="meeting_date"
                        v-model="meeting.meeting_date_gross"
                        type="datetime-local"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Turma Maio 2025"
                        required
                    />
                </div>

                <div class="col-md-8">
                    <label for="place_held" class="form-label"> Endereço do encontro </label>

                    <input
                        id="place_held"
                        v-model="meeting.place_held"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Rua São Jorge n 99 - Próximo a Saúde Cia"
                    />
                </div>

                <div class="col-md-4 mt-2">
                    <label for="total_participants" class="form-label"> Total de presentes </label>

                    <input
                        id="total_participants"
                        v-model="meeting.total_participants"
                        type="number"
                        autocomplete="off"
                        class="form-control"
                    />
                </div>

                <div class="col-md-4 mt-2">
                    <label for="total_visitors" class="form-label"> Total de visitantes </label>

                    <input
                        id="total_visitors"
                        v-model="meeting.total_visitors"
                        type="number"
                        autocomplete="off"
                        class="form-control"
                    />
                </div>

                <div class="col-md-4 mt-2">
                    <label for="total_salvation" class="form-label"> Total de salvações </label>

                    <input
                        id="total_salvation"
                        v-model="meeting.total_salvation"
                        type="number"
                        autocomplete="off"
                        class="form-control"
                    />
                </div>
            </fieldset>
        </form>

        <hr />

        <template v-if="meeting.host">
            <h6 class="font-semibold">Anfitrião desse encontro</h6>

            <div class="row" v-if="meeting.host">
                <div class="col-md-2 col-6 counselors mt-3">
                    <Link
                        class="d-block text-center"
                        :href="`/dashboard/members/${meeting.host.member.id}`"
                    >
                        <img
                            class="image-counselor border border-info border-3"
                            :src="`${meeting.host.member.identification_photo}`"
                            alt=""
                        />
                    </Link>
                    <span class="name d-block text-center mt-2">
                        {{ meeting.host.member.clean_name }}
                    </span>
                </div>
            </div>
            <hr />
        </template>

        <template v-if="meeting.member_visitor_meeting_group_connection.length">
            <h5 class="font-bold">Visitantes desse encontro</h5>
            <div class="search-form-card border-0 shadow-none">
                <div class="table-responsive">
                    <table class="table align-middle table-hover" id="table-members">
                        <thead class="table-light">
                            <tr>
                                <th>#</th>
                                <th>Nome</th>
                                <th class="text-center" scope="col">Telefone</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(
                                    item, index
                                ) in meeting.member_visitor_meeting_group_connection"
                                :key="index"
                                @click="goToMemberVisitPage(item.visit_id)"
                            >
                                <td>
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    <img
                                        class="rounded-circle me-3"
                                        width="30"
                                        :src="`${item.visit_photo}`"
                                        alt=""
                                    />
                                    <span>
                                        {{ item.visit_name }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    {{ item.visit_phone.phone_number ?? 'Não informado' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr />
        </template>

        <template v-if="meeting.member_new_birth_meeting_group_connection.length">
            <h5 class="font-bold">Conversões nesse encontro</h5>
            <div class="search-form-card border-0 shadow-none">
                <div class="table-responsive">
                    <table class="table align-middle table-hover" id="table-members">
                        <thead class="table-light">
                            <tr>
                                <th>#</th>
                                <th>Nome</th>
                                <th class="text-center" scope="col">Telefone</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(
                                    item, index
                                ) in meeting.member_new_birth_meeting_group_connection"
                                :key="index"
                                @click="goToMemberNewBirthPage(item.new_birth_id)"
                            >
                                <td>
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    <img
                                        class="rounded-circle me-3"
                                        width="30"
                                        :src="`${item.new_birth_photo}`"
                                        alt=""
                                    />
                                    <span>
                                        {{ item.new_birth_name }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    {{ item.new_birth_phone.phone_number ?? 'Não informado' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr />
        </template>

        <h5 class="font-bold">Frequência dos membros</h5>

        <div class="search-form-card border-0 shadow-none">
            <div class="table-responsive">
                <table class="table align-middle table-hover" id="table-members">
                    <thead class="table-light">
                        <tr>
                            <th>#</th>
                            <th>Nome do membro</th>
                            <th class="text-center" scope="col">Status</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(item, index) in members"
                            :key="index"
                            @click="goToMemberPage(item.id)"
                            :class="{
                                'table-success': item.status == 'PRESENT',
                            }"
                        >
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>
                                <img
                                    class="rounded-circle me-3"
                                    width="30"
                                    :src="item.member.identification_photo"
                                    alt=""
                                />
                                <span>
                                    {{ item.member.clean_name }}
                                </span>
                            </td>
                            <td class="text-center">
                                <Badge :name="item.status" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </Dashboard>
</template>
