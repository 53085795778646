<script setup>
import { router, Link } from '@inertiajs/vue3';

import Layout from '../Layout.vue';

const props = defineProps({
    group: Object,
    meetings: Object,
});

import useDateFormat from '@/Pages/Dashboard/Composables/useDateFormat.vue';

const { simplifyDate } = useDateFormat();

const goToMeetingPage = (meetingID) =>
    router.get(`/dashboard/group-connections/${props.group.id}/meetings/${meetingID}`);

const getAttendanceClass = (attendancePercentage) => {
    if (attendancePercentage > 75) {
        return 'text-success';
    } else if (attendancePercentage > 50) {
        return 'text-primary';
    } else if (attendancePercentage > 40) {
        return 'text-danger';
    } else if (attendancePercentage > 0) {
        return 'text-secondary';
    }
    return '';
};
</script>

<template>
    <Layout :group="group">
        <div
            class="row d-flex justify-content-center px-4 py-3 search-form-card border-0 shadow-none"
        >
            <div class="d-flex justify-content-between mb-2 align-items-center">
                <h5 class="font-bold mb-0">Encontros desse grupo</h5>
                <Link
                    class="btn btn-primary btn-redirection"
                    :href="`/dashboard/group-connections/${group.id}/meetings/create`"
                >
                    Novo
                </Link>
            </div>

            <hr />

            <div class="table-responsive" v-if="meetings.data.length">
                <table class="table align-middle table-hover" id="table-members">
                    <thead class="table-light">
                        <tr>
                            <th>#</th>
                            <th>Data do encontro</th>
                            <th class="text-center">Presentes</th>
                            <th class="text-center" scope="col">Visitantes</th>
                            <th class="text-center" scope="col">Taxa de presença</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(item, index) in meetings.data"
                            :key="index"
                            @click="goToMeetingPage(item.id)"
                            :class="{
                                'table-success': item.active,
                            }"
                        >
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>
                                {{ simplifyDate(item.meeting_date) }}
                            </td>
                            <td class="text-center">
                                {{ item.total_participants }}
                            </td>
                            <td class="text-center">
                                {{ item.total_visitors }}
                            </td>
                            <td class="text-center">
                                <span
                                    :class="
                                        getAttendanceClass(item.attendance.attendance_percentage)
                                    "
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :data-bs-title="`${item.attendance.present_members}/${item.attendance.total_members} participantes presentes`"
                                    >{{ item.attendance.attendance_percentage }} %
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <p
                    class="p-3 bg-info fs-14 bg-opacity-10 rounded-end font-semibold border-primary border-start border-5"
                >
                    Nenhum encontro realizado ainda
                </p>
            </div>
        </div>
    </Layout>
</template>
