<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    members: Object,
    types: Object,
});

const goToMemberPage = (memberId) => router.get(`/dashboard/members/${memberId}`);

const searchForm = reactive({
    name: null,
    type: '',
});

const seacher = () =>
    router.get('/dashboard/members', searchForm, {
        preserveState: true,
    });

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const initializeSearchForm = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const fields = ['name', 'type'];
    fields.forEach((field) => {
        if (urlParams.has(field)) {
            searchForm[field] = urlParams.get(field);
        }
    });

    if (!urlParams.has('type')) {
        searchForm.type = 'MEMBER';
    }
};

onMounted(() => {
    initializeSearchForm();
});
</script>

<template>
    <Dashboard page-title="Membros">
        <Breadcrumb current-page-name="Membros" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Membros</h4>
                <Link :href="`/dashboard/members/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
                    <div class="font-bold col-12 col-sm-6">Baixar membros</div>
                    <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                        <a
                            class="btn btn-danger fw-bold fs-12"
                            :href="`/dashboard/members/pdf/export?name=${searchForm.name}&type=${searchForm.type}`"
                        >
                            <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                        </a>
                        <a
                            class="btn btn-success ms-2 fw-bold fs-12"
                            :href="`/dashboard/members/excel/export?name=${searchForm.name}&type=${searchForm.type}`"
                        >
                            <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                        </a>
                    </div>
                </div>
                <hr class="mx-3" />
                <div class="px-3">
                    <div class="row g-3">
                        <div class="col-md-8">
                            <label class="form-label" for="floatingInput">Nome do visitante</label>
                            <input
                                id="floatingInput"
                                type="email"
                                v-model="searchForm.name"
                                class="form-control"
                                :value="` `"
                                placeholder="Aline Andrade"
                                @input="compositionSeacher('name', $event)"
                            />
                        </div>
                        <div class="col-md-4">
                            <label for="type" class="form-label">Status</label>
                            <select
                                id="type"
                                v-model="searchForm.type"
                                class="form-select form-select"
                                aria-label="Small select example"
                                @change="seacher()"
                            >
                                <option value="0">Todas opções</option>
                                <option
                                    v-for="(item, index) in types"
                                    :key="index"
                                    :value="item.value"
                                >
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row px-4 mt-3">
                    <hr />
                    <div class="table-responsive">
                        <table class="table align-middle table-hover" id="table-members">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome do membro</th>
                                    <th class="text-center" scope="col">Status</th>
                                    <th class="text-center" scope="col">Telefone</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(member, index) in members.data"
                                    :key="index"
                                    @click="goToMemberPage(member.id)"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-circle me-3"
                                            width="30"
                                            :src="member.identification_photo"
                                            alt=""
                                        />
                                        <span>
                                            {{ member.clean_name }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <Badge :name="member.type" />
                                    </td>
                                    <td class="text-center">
                                        <template v-if="member.phone_number?.phone_number.length">
                                            {{ member.phone_number?.phone_number }}
                                        </template>
                                        <template v-else>
                                            <font-awesome-icon
                                                :icon="['fas', 'minus']"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                data-bs-title="Nenhum telefone adicionado"
                                            />
                                        </template>
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="5">
                                        <Pagination :data="members.meta" reference-date="members" />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
