<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    members: Object,
    schedules: Object,
    counselors: Object,
});

const goToBaptismHolySpiritPage = (memberId) =>
    router.get(`/dashboard/baptism-holy-spirits/${memberId}`);

const clearQueryParams = () => router.replace('/dashboard/baptism-holy-spirits');

const searchForm = reactive({
    name: null,
    start_date: '',
    end_date: '',
    counselor_id: 0,
    schedule_id: 0,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/baptism-holy-spirits', searchForm, {
        preserveState: true,
    });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Batismos Espírito Santo">
        <Breadcrumb current-page-name="Batismos Espírito Santo" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Batismos ES</h4>
                <Link
                    :href="`/dashboard/baptism-holy-spirits/create`"
                    class="btn btn-primary btn-add"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
                <div class="font-bold col-12 col-sm-6">Baixar registros</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="`/dashboard/baptism-holy-spirits/pdf/export?name=${searchForm.name}&schedule_id=${searchForm.schedule_id}&start_date=${searchForm.start_date}&end_date=${searchForm.end_date}&counselor_id=${searchForm.counselor_id}`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="`/dashboard/baptism-holy-spirits/excel/export?name=${searchForm.name}&schedule_id=${searchForm.schedule_id}&start_date=${searchForm.start_date}&end_date=${searchForm.end_date}&counselor_id=${searchForm.counselor_id}`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>
            <hr class="mx-3" />
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-6">
                        <label class="form-label" for="floatingInput">Nome do membro</label>
                        <input
                            id="floatingInput"
                            type="type"
                            class="form-control"
                            placeholder="Aline Andrade"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                    <div class="col-6 col-sm-3">
                        <label for="startDate" class="form-label">Data de início</label>
                        <input
                            id="startDate"
                            v-model="searchForm.start_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-6 col-sm-3">
                        <label for="endDate" class="form-label">Data de fim</label>
                        <input
                            id="endDate"
                            v-model="searchForm.end_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-md-7">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Selecionar programação</label
                        >
                        <select
                            id="schedule_id"
                            v-model="searchForm.schedule_id"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todas as programações</option>
                            <option
                                v-for="(item, scheduleIndex) in schedules"
                                :key="scheduleIndex"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Conselheiro</label
                        >
                        <select
                            id="type"
                            v-model="searchForm.counselor_id"
                            class="form-select"
                            @change="seacher()"
                        >
                            <option value="0">Todas os conselheiros</option>
                            <option
                                v-for="(item, counselorIndex) in counselors"
                                :key="counselorIndex"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row px-4 mt-3">
                <hr />
                <div class="table-responsive">
                    <table class="table align-middle table-hover" id="table-baptism-holy-spirits">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">#</th>
                                <th scope="col">Nome</th>
                                <th class="text-center" scope="col">Programação</th>
                                <th class="text-center" scope="col">Conselheiros</th>
                                <th class="text-center" scope="col">Data</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(member, memberIndex) in members.data"
                                :key="memberIndex"
                                @click="goToBaptismHolySpiritPage(member.id)"
                            >
                                <td class="text-center">
                                    {{ memberIndex + 1 }}
                                </td>
                                <td>
                                    {{ member.member.clean_name }}
                                </td>
                                <td class="text-center">
                                    {{ member.schedule_name }}
                                </td>
                                <td class="text-center">
                                    <template v-if="member.counselors.length">
                                        <Link
                                            v-for="(counselor, counselorIndex) in member.counselors"
                                            :key="counselorIndex"
                                            :href="`/dashboard/counselors/${counselor.id}`"
                                        >
                                            <img
                                                height="30"
                                                width="30"
                                                class="rounded-circle border border-1 border-ligth"
                                                :src="`/storage/${counselor.identification_photo}`"
                                                alt=""
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                :data-bs-title="counselor.name"
                                                style="margin-left: -8px"
                                            />
                                        </Link>
                                    </template>
                                    <template v-else>
                                        <font-awesome-icon
                                            :icon="['fas', 'minus']"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            data-bs-title="Nenhum conselheiro definido"
                                        />
                                    </template>
                                </td>
                                <td class="text-center">
                                    {{ member.new_baptism_holy_spirit_date }}
                                </td>
                            </tr>
                        </tbody>

                        <tfoot class="bg-white">
                            <tr>
                                <td colspan="6">
                                    <Pagination :data="members.meta" reference-date="members" />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
