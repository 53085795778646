<script setup>
import { ref } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    instructor: Object,
});

const form = useForm({
    active: props.instructor.active ? true : false,
});

const update = () => form.put(`/dashboard/discipleship-instructor-member/${props.instructor.id}`);

const instructorEditModalCloseButton = ref(null);

const destroy = () => {
    instructorEditModalCloseButton.value.click();
    router.delete(`/dashboard/discipleship-instructor-member/${props.instructor.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar ministro">
        <Breadcrumb
            current-page-name="Editar ministro"
            :link-list="[
                {
                    previous_page_url: 'discipleship-instructor-member',
                    previous_page_name: 'Ministros',
                },
            ]"
        />
        <div id="create-instructor">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/discipleship-instructor-member/${instructor.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#instructorEditModal"
                        href="#"
                        class="btn btn-danger btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                        Deletar
                    </a>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="form-check d-flex align-itemscenter">
                    <input
                        class="form-check-input"
                        v-model="form.active"
                        type="checkbox"
                        id="defaultCheck1"
                    />
                    <label class="form-check-label ms-3" for="defaultCheck1">
                        Ministro se encontra ativo ?
                    </label>
                </div>
                <hr />
                <ConfirmationModal
                    modal_id="instructorEditModal"
                    modal_title="Confirmação de remoção do registro"
                >
                    <template v-slot:modal-text>
                        Você tem certeza que deseja excluir o registro?
                    </template>
                    <template v-slot:modal-footer>
                        <button
                            type="button"
                            ref="instructorEditModalCloseButton"
                            class="btn btn-secondary text-white"
                            data-bs-dismiss="modal"
                        >
                            Voltar
                        </button>
                        <button @click="destroy" type="button" class="btn btn-danger text-white">
                            Deletar registro
                        </button>
                    </template>
                </ConfirmationModal>
            </Form>
        </div>
    </Dashboard>
</template>
