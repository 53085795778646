<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    musics: Object,
    artists: Object,
    themes: Object,
});

const goToMusicPage = (musicId) => router.get(`/dashboard/musics/${musicId}`);

const clearQueryParams = () => router.replace('/dashboard/musics');

const searchForm = reactive({
    name: null,
    artist_id: 0,
    start_date: null,
    end_date: null,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () => router.get('/dashboard/musics', searchForm, { preserveState: true });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Músicas">
        <Breadcrumb current-page-name="Músicas" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Músicas</h4>
                <Link :href="`/dashboard/musics/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row g-3 px-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome da música</label>
                        <input
                            id="floatingInput"
                            type="email"
                            v-model="searchForm.name"
                            class="form-control"
                            :value="` `"
                            placeholder="Seu Amor"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                    <div class="col-md-4">
                        <label class="form-label" for="date">Tocada em</label>
                        <input
                            id="date"
                            type="date"
                            v-model="searchForm.start_date"
                            class="form-control"
                            :value="` `"
                            placeholder="Seu Amor"
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-md-4">
                        <label class="form-label" for="date">Até</label>
                        <input
                            id="date"
                            type="date"
                            v-model="searchForm.end_date"
                            class="form-control"
                            :value="` `"
                            placeholder="Seu Amor"
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-md-4">
                        <label for="artist_id" class="form-label">Artista</label>
                        <select
                            id="artist_id"
                            v-model="searchForm.artist_id"
                            class="form-select form-select"
                            aria-label="Small select example"
                            @change="seacher()"
                        >
                            <option value="0">Todos</option>
                            <option v-for="(item, index) in artists" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row p-3">
                    <div class="table-responsive">
                        <table class="table m-0 align-middle table-hover" id="table-full-musics">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th class="text-center" scope="col">Artista</th>
                                    <th class="text-center" scope="col">Youtube</th>
                                    <th class="text-center" scope="col">Duração</th>
                                    <th class="text-center" scope="col">Reproduções</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(music, musicIndex) in musics.data"
                                    :key="musicIndex"
                                    @click="goToMusicPage(music.id)"
                                >
                                    <td class="text-center">
                                        {{ musicIndex + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-pill me-3"
                                            height="35"
                                            width="35"
                                            :src="`/storage/${music.cover_photo}`"
                                            alt=""
                                        />
                                        <span>
                                            {{ music.name }}
                                        </span>
                                    </td>

                                    <td class="text-center">
                                        <Link
                                            v-if="music.artist"
                                            :href="`/dashboard/artists/${music.artist_id}`"
                                        >
                                            <img
                                                height="35"
                                                width="35"
                                                class="rounded-circle border border-1 border-ligth"
                                                :src="`/storage/${music.artist.profile_photo}`"
                                                alt=""
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                :data-bs-title="music.artist.name"
                                            />
                                        </Link>
                                        <span
                                            v-else
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-title="Não vinculado a evento"
                                        >
                                            <font-awesome-icon :icon="['fas', 'minus']" />
                                        </span>
                                    </td>

                                    <td class="text-center">
                                        <a
                                            target="_blank"
                                            class="btn btn-link text-danger p-0 fs-3"
                                            :class="{ disabled: !music.youtube_link }"
                                            :href="music.youtube_link"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            data-bs-title="Assistir no Youtube"
                                        >
                                            <font-awesome-icon :icon="['fab', 'youtube']" />
                                        </a>
                                    </td>

                                    <td class="text-center">{{ music.duration }}</td>
                                    <td class="text-center">
                                        {{ music.reproductions ? `${music.reproductions} x` : '-' }}
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="6">
                                        <Pagination :data="musics.meta" reference-date="musics" />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
