<script setup>
import { useForm, router, Link } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const form = useForm({
    father_name: null,
    mother_name: null,
    child_name: null,
    child_age: null,
    observations: null,
    scheduled_date: null,
});

const store = () => form.post(`/dashboard/children-consecration`);
</script>

<template>
    <Dashboard page-title="Novo">
        <Breadcrumb
            current-page-name="Novo"
            :link-list="[
                {
                    previous_page_url: 'children-consecration',
                    previous_page_name: 'Consagrações',
                },
            ]"
        />

        <h4 class="my-3">Nova Consagração</h4>
        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="row g-3">
                <div class="col-sm-6">
                    <label for="father_name" class="form-label">Nome do pai</label>
                    <small class="optional ms-2">(opcional)</small>
                    <input
                        placeholder="João Pedro de Souza"
                        type="text"
                        v-model="form.father_name"
                        class="form-control"
                        name="father_name"
                        id="father_name"
                        autocomplete="on"
                        aria-describedby="father_nameHelp"
                    />
                    <small v-if="form.errors.father_name" class="text-danger mt-3">
                        {{ form.errors.father_name }}
                    </small>
                </div>

                <div class="col-sm-6">
                    <label for="mother_name" class="form-label"> Nome da mãe </label>
                    <small class="optional ms-2">(opcional)</small>
                    <input
                        placeholder="Maria Lima de Souza"
                        id="mother_name"
                        v-model="form.mother_name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.mother_name" class="text-danger mt-3">
                        {{ form.errors.mother_name }}
                    </small>
                </div>

                <div class="col-sm-12">
                    <label for="child_name" class="form-label">Nome da criança</label>
                    <input
                        placeholder="Miguel Lima Santos"
                        type="text"
                        v-model="form.child_name"
                        class="form-control"
                        name="child_name"
                        id="child_name"
                        autocomplete="on"
                        aria-describedby="child_nameHelp"
                    />
                    <small v-if="form.errors.child_name" class="text-danger mt-3">
                        {{ form.errors.child_name }}
                    </small>
                </div>
                <div class="col-sm-4">
                    <label for="child_age" class="form-label">Idade da criança</label>
                    <input
                        type="text"
                        v-maska
                        v-model="form.child_age"
                        class="form-control"
                        name="child_age"
                        id="child_age"
                        autocomplete="on"
                        aria-describedby="child_ageHelp"
                        data-maska="##"
                    />
                    <small v-if="form.errors.child_age" class="text-danger mt-3">
                        {{ form.errors.child_age }}
                    </small>
                </div>
                <div class="col-sm-8">
                    <label for="scheduled_date" class="form-label">Data da consagração</label>
                    <input
                        placeholder="6 meses"
                        type="date"
                        v-model="form.scheduled_date"
                        class="form-control"
                        name="scheduled_date"
                        id="scheduled_date"
                        autocomplete="on"
                        aria-describedby="scheduled_dateHelp"
                    />
                    <small v-if="form.errors.scheduled_date" class="text-danger mt-3">
                        {{ form.errors.scheduled_date }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="observations" class="form-label"> Observações </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="observations"
                        v-model="form.observations"
                        type="text"
                        class="form-control"
                        name="observations"
                        placeholder="Forneça alguma informação importante dessa consagração"
                        rows="5"
                    />

                    <small v-if="form.errors.observations" class="text-danger mt-3">
                        {{ form.errors.observations }}
                    </small>
                </div>
            </div>
        </Form>
    </Dashboard>
</template>
