<script setup>
import { reactive, defineProps } from 'vue';
import { Link, router } from '@inertiajs/vue3';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    discipleships: Object,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const goToClassPage = (discipleshipID) => router.get(`/dashboard/discipleships/${discipleshipID}`);

const searchForm = reactive({
    name: null,
});

const seacher = () =>
    router.get('/dashboard/discipleships', searchForm, {
        preserveState: true,
    });
</script>

<template>
    <Dashboard page-title="Turmas">
        <Breadcrumb current-page-name="Turmas" />
        <div class="row mt-3 mb-4">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Turmas</h4>
                <Link href="/dashboard/discipleships/create" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome da turma</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            :value="` `"
                            placeholder="Turma Maio 2025"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                </div>
            </div>

            <div class="row mt-3 px-3">
                <div class="table-responsive">
                    <table class="table m-0 align-middle table-hover" id="table-discipleship">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">#</th>
                                <th scope="col">Nome da Turma</th>
                                <th class="text-center" scope="col">Inscritos</th>
                                <th class="text-center" scope="col">Situação</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(discipleship, discipleshipIndex) in discipleships.data"
                                :key="discipleshipIndex"
                                @click="goToClassPage(discipleship.id)"
                            >
                                <td class="text-center">
                                    {{ discipleshipIndex + 1 }}
                                </td>
                                <td>
                                    {{ discipleship.name }}
                                </td>
                                <td class="text-center">
                                    {{ discipleship.total_registrations }} pessoa(s)
                                </td>
                                <td class="text-center">
                                    <Badge :name="discipleship.open ? 'OPEN' : 'CLOSED'" />
                                </td>
                            </tr>
                        </tbody>

                        <tfoot class="bg-white">
                            <tr>
                                <td colspan="5">
                                    <Pagination
                                        :data="discipleships.meta"
                                        reference-date="discipleships"
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
