<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { Head, Link } from '@inertiajs/vue3';

import Sidebar from './Sidebar.vue';
import Navbar from './Navbar.vue';

import useAxios from '@/Pages/Dashboard/Composables/useAxios.vue';
import Toast from '@/Pages/Dashboard/Shared/Toast.vue';
import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

import { Tooltip } from 'bootstrap';
const { instance: axios } = useAxios();

const props = defineProps({ pageTitle: String });

const sidebarOpen = ref(true);
const responsiveSidebarIsOpen = ref(false);
const tooltips = [];

const modal = ref(null);
const modalName = ref(null);

const name = ref(null);
const members = ref([]);

const searchStarted = ref(false);
const processing = ref(false);

const compositionUpdate = (event) => {
    name.value = event.target.value;
};

watch(name, (value) => {
    seacher();
});

const seacher = () => {
    searchStarted.value = true;
    processing.value = true;
    axios
        .get(`/dashboard/members/search?name=${name.value}`)
        .then((response) => {
            members.value = response.data.members;
        })
        .finally(() => {
            processing.value = false;
        });
};

const changeSidebarState = () => (sidebarOpen.value = !sidebarOpen.value);
const changeStateResponsiveSidebar = () =>
    (responsiveSidebarIsOpen.value = !responsiveSidebarIsOpen.value);

onMounted(() => {
    const tooltipElements = document.querySelectorAll("[data-bs-toggle='tooltip']");
    tooltipElements.forEach((element) => {
        tooltips.push(
            new Tooltip(element, {
                trigger: 'hover',
            })
        );
    });

    const modalElement = document.getElementById('memberSearchModal');

    modalElement.addEventListener('shown.bs.modal', () => {
        modalName.value.focus();
        modalName.value.click();
    });
});

onBeforeUnmount(() => {
    tooltips.forEach((tooltip) => {
        tooltip.dispose();
    });
});

const environment = import.meta.env.VITE_ENVIRONMENT;
</script>

<template>
    <div
        class="fixed-top bg-warning text-center fs-12 d-flex justify-content-center align-items-center"
        v-if="environment == 'staging'"
    >
        <span>Ambiente dedicado para testes do sistema.</span>
        <span class="d-none d-sm-block ms-2"> Fique à vontade para realizar qualquer ação!</span>
    </div>

    <div
        :class="{
            'sidebar-folded': !sidebarOpen,
            'sidebar-open': responsiveSidebarIsOpen,
        }"
    >
        <Head :title="pageTitle" />

        <div class="main-wrapper">
            <Sidebar
                :open="sidebarOpen"
                @change-sidebar-state="changeSidebarState"
                @change-state-responsive-sidebar="changeStateResponsiveSidebar"
            />
            <div class="page-wrapper">
                <Navbar @change-state-responsive-sidebar="changeStateResponsiveSidebar" />
                <div class="page-content">
                    <Toast />
                    <div class="row">
                        <div class="col-xxl-11 col-xl-12 mx-auto">
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="memberSearchModal"
            tabindex="-1"
            aria-labelledby="memberSearchModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="memberSearchModalLabel">Buscar membros</h1>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            ref="modal"
                        ></button>
                    </div>
                    <div class="modal-body bg-light p-3">
                        <form action="">
                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label"
                                    >Nome</label
                                >
                                <input
                                    type="text"
                                    ref="modalName"
                                    class="form-control"
                                    id="exampleFormControlInput1"
                                    v-model="name"
                                    @input="compositionUpdate"
                                    placeholder="Digite o nome do membro"
                                />
                            </div>
                        </form>

                        <ul class="list-group">
                            <Link
                                :href="`/dashboard/members/${member.id}`"
                                v-for="(member, index) in members"
                                :key="index"
                                @click="modal.click()"
                                class="list-group-item mt-2 d-flex align-items-center justify-content-between bg-white rounded-3 border"
                            >
                                <div class="d-flex">
                                    <img
                                        class="rounded-circle"
                                        :src="`${member.identification_photo}`"
                                        alt=""
                                        style="width: 50px; height: 50px"
                                    />
                                    <div class="fs-12 font-semibold ms-3">
                                        <span>{{ member.name }}</span
                                        ><br />
                                        <Badge :name="member.type" />
                                    </div>
                                </div>
                                <font-awesome-icon :icon="['fas', 'angle-right']" />
                            </Link>
                        </ul>

                        <div class="d-flex justify-content-center" v-if="processing">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                        <div v-else>
                            <div
                                class="d-block text-center my-3"
                                v-if="Object.values(members).length > 0"
                            >
                                <Link
                                    :href="`/dashboard/members?name=${name}`"
                                    class="btn btn-primary fs-12 text-uppercase rounded-2 text-center font-semibold"
                                    @click="modal.click()"
                                    >Ver todos os resultados</Link
                                >
                            </div>
                            <div v-else-if="Object.values(members).length == 0 && searchStarted">
                                <div>Nenhum membro foi encontrado</div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            data-bs-dismiss="modal"
                            type="button"
                            class="btn btn-secondary text-white fs-14 ms-3"
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
