<script setup>
import { defineProps } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    instructors: Object,
});

const goToInstructorPage = (instructorID) =>
    router.get(`/dashboard/discipleship-instructor-member/${instructorID}`);
</script>

<template>
    <Dashboard page-title="Ministros">
        <Breadcrumb current-page-name="Ministros" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Ministros</h4>
                <Link
                    :href="`/dashboard/discipleship-instructor-member/create`"
                    class="btn btn-primary btn-add"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row p-3">
                    <div class="table-responsive">
                        <table
                            class="table m-0 align-middle table-hover"
                            id="table-discipleship-instructor-member"
                        >
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome do ministro</th>
                                    <th scope="col">Ativo</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(instructor, memberIndex) in instructors.data"
                                    :key="memberIndex"
                                    @click="goToInstructorPage(instructor.id)"
                                >
                                    <td class="text-center">
                                        {{ memberIndex + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-pill me-3"
                                            height="35"
                                            width="35"
                                            :src="`${instructor.member.identification_photo}`"
                                            alt=""
                                        />
                                        <span>
                                            {{ instructor.member.name }}
                                        </span>
                                    </td>
                                    <td>
                                        <Badge :name="instructor.active ? 'YES' : 'NOT'" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
