<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    plannings: Object,
});

const goToPlanningPage = (musicId) => router.get(`/dashboard/musical-plannings/${musicId}`);

const clearQueryParams = () => router.replace('/dashboard/musical-plannings');

const searchForm = reactive({
    theme: null,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/musical-plannings', searchForm, { preserveState: true });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Planejamento">
        <Breadcrumb current-page-name="Planejamentos" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Planejamentos</h4>
                <Link :href="`/dashboard/musical-plannings/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row g-3 px-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome do tema</label>
                        <input
                            id="floatingInput"
                            type="email"
                            v-model="searchForm.theme"
                            class="form-control"
                            :value="` `"
                            placeholder="Cura Divina"
                            @input="compositionSeacher('theme', $event)"
                        />
                    </div>
                </div>

                <div class="row p-3">
                    <div class="table-responsive">
                        <table
                            class="table m-0 align-middle table-hover"
                            id="table-musical-plannings"
                        >
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Tema</th>
                                    <th class="text-center" scope="col">Evento</th>
                                    <th class="text-center" scope="col">Músicas</th>
                                    <th class="text-center" scope="col">Paleta</th>
                                    <th class="text-center" scope="col">Data</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(music, musicIndex) in plannings.data"
                                    :key="musicIndex"
                                    @click="goToPlanningPage(music.id)"
                                >
                                    <td class="text-center">
                                        {{ musicIndex + 1 }}
                                    </td>

                                    <td>{{ music.theme }}</td>

                                    <td class="text-center">
                                        <Link :href="`/dashboard/events/${music.event_id}`">
                                            <img
                                                height="35"
                                                width="35"
                                                class="rounded-circle border border-1 border-ligth"
                                                :src="`/storage/${music.event.banner}`"
                                                alt=""
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                :data-bs-title="music.event.name"
                                            />
                                        </Link>
                                    </td>

                                    <td class="text-center">{{ music.musics.length }}</td>
                                    <td class="text-center">
                                        <div
                                            class="row d-flex justify-content-center"
                                            v-if="music.colors.length"
                                        >
                                            <div
                                                v-for="(item, index) in music.colors"
                                                :key="index"
                                                :style="{
                                                    width: '30px',
                                                    height: '30px',
                                                    backgroundColor: `${item.color}`,
                                                    borderRadius: '50%',
                                                    marginLeft: '-8px',
                                                }"
                                                class="border border-2 border-info"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                :data-bs-title="item.name"
                                            ></div>
                                        </div>

                                        <span
                                            v-else
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-title="Nenhuma cadastrada"
                                        >
                                            <font-awesome-icon :icon="['fas', 'minus']" />
                                        </span>
                                    </td>
                                    <td class="text-center">{{ music.event_date }}</td>
                                </tr>
                            </tbody>

                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="6">
                                        <Pagination
                                            :data="plannings.meta"
                                            reference-date="plannings"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
