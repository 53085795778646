<script setup>
import { reactive, defineProps } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import WaterBaptismCard from './Components/WaterBaptismCard.vue';

const props = defineProps({
    waterBaptisms: Object,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const searchForm = reactive({
    responsible_id: null,
    identifier: null,
    start_date: '',
    end_date: '',
});

const seacher = () =>
    router.get('/dashboard/water-baptisms', searchForm, {
        preserveState: true,
    });
</script>

<template>
    <Dashboard page-title="Turmas de batismo">
        <Breadcrumb current-page-name="Turmas de batismo" />
        <div class="row mt-3 mb-4">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Turmas de batismo</h4>
                <Link href="/dashboard/water-baptisms/create" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-6">
                        <label class="form-label" for="floatingInput">Nome de identificação</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            :value="` `"
                            placeholder="Culto de celebração"
                            @input="compositionSeacher('identifier', $event)"
                        />
                    </div>
                    <div class="col-6 col-sm-6">
                        <label for="startDate" class="form-label">Data de início</label>
                        <input
                            id="startDate"
                            v-model="searchForm.start_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-6 col-sm-6">
                        <label for="endDate" class="form-label">Data de fim</label>
                        <input
                            id="endDate"
                            v-model="searchForm.end_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                </div>
            </div>

            <div class="row mt-3 px-3">
                <hr />
                <WaterBaptismCard :data="waterBaptisms.data" />
                <Pagination :data="waterBaptisms.meta" reference-date="waterBaptisms" />
            </div>
        </div>
    </Dashboard>
</template>
