<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';
import CreatorRecord from '@/Pages/Dashboard/Shared/CreatorRecord.vue';

import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    request: Object,
    isPastor: Boolean,
    isLeader: Boolean,
    departments: Object,
    creatorRegistration: Boolean,
});

const formPastor = useForm({
    status: 'on',
    comments_pastor: null,
    type: 'pastor',
});

const formLeader = useForm({
    status: 'on',
    comments_pastor: props.request.comments_pastor,
    type: 'leader',
});

const sendResponseRequestPastor = () =>
    formPastor.put(
        `/dashboard/departments/entry-requests/${props.request.id}/send-response-request`
    );

const sendResponseRequestLeader = () =>
    formLeader.put(
        `/dashboard/departments/entry-requests/${props.request.id}/send-response-request`
    );
</script>

<template>
    <Dashboard page-title="Solicitação">
        <Breadcrumb
            current-page-name="Solicitação"
            :link-list="[
                {
                    previous_page_url: `departments/entry-requests`,
                    previous_page_name: `Solicitações`,
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Pedido de entrada no {{ request.department.name }}</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/departments/entry-requests/edit/${request.id}`"
                    class="btn btn-add btn-primary me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                    Editar dados
                </Link>
            </div>
        </div>

        <div class="card-simple p-4">
            <div class="row g-0 d-flex justify-content-center align-items-center">
                <div class="col-md-2 text-md-end text-center">
                    <img
                        width="100"
                        :src="request.member_photo"
                        class="img-fluid rounded-circle z-3"
                        alt=""
                        :style="{
                            border: `5px solid ${request.department.identification_color}`,
                            marginRight: '-20px',
                        }"
                    />
                </div>
                <div class="col-md-2 text-md-start text-center mt-3 mt-sm-0">
                    <img
                        width="100"
                        :src="`/storage/${request.department.image_identification}`"
                        class="img-fluid rounded-circle"
                        alt=""
                        :style="{ border: `5px ${request.department.identification_color}` }"
                    />
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12 d-flex align-items-center justify-content-center">
                    <div class="card-body">
                        <h5
                            class="card-title mt-3 mt-md-0 text-center font-semibold text-secondary fs-14"
                        >
                            <Link :href="`/dashboard/members/${request.member_id}`">{{
                                request.member_name
                            }}</Link>
                            deseja fazer parte do Departamento
                            <Link :href="`/dashboard/departments/${request.department.id}`">{{
                                request.department.name
                            }}</Link>
                        </h5>
                        <p class="card-text mt-3"></p>
                    </div>
                </div>
            </div>

            <hr class="mt-0" />

            <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item" v-if="request.status_response_pastor == 'PENDING'">
                    <h2 class="accordion-header">
                        <button
                            class="accordion-button font-semibold"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                        >
                            Solicitação aguardando avaliação do pastor
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
                        <div class="accordion-body">
                            <div v-if="isPastor">
                                Como pastor, você pode agora decidir se deseja
                                <strong>aprovar</strong> ou <strong>recusar</strong> esta
                                solicitação. Caso aprove, ela será encaminhada ao líder do
                                departamento. Se optar por não aprovar, o processo será encerrado
                                neste ponto.

                                <p
                                    class="bg-warning bg-opacity-10 border-start border-warning border-5 p-3 mt-3"
                                >
                                    Ao recusar a solicitação, recomendamos que informe o motivo no
                                    campo de observações.
                                </p>

                                <template v-if="departments.length">
                                    <hr />

                                    <h4 class="fs-16">Informações adicionais</h4>

                                    <p
                                        class="bg-info bg-opacity-10 border-start border-info border-5 p-3 mt-3"
                                    >
                                        {{ request.member_name }} atualmente estar servindo em
                                        {{ departments.length }} departamento(s). Segue abaixo:
                                    </p>

                                    <ul class="list-group">
                                        <li
                                            v-for="(item, index) in departments"
                                            :key="index"
                                            class="list-group-item d-flex justify-content-between align-items-center"
                                        >
                                            <span class="font-semibold">{{ item.name }}</span>
                                            <a
                                                :href="`/dashboard/departments/${item.id}`"
                                                class="btn btn-primary btn-add z-2"
                                                target="_blank"
                                                >Acessar</a
                                            >
                                        </li>
                                    </ul>
                                </template>

                                <hr />

                                <Form
                                    :method="sendResponseRequestPastor"
                                    class="shadow-none mt-0 pt-0 border-0"
                                    buttonName="Enviar solicitação"
                                >
                                    <div class="d-flex justify-content-center">
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="options-base"
                                            id="option5"
                                            autocomplete="off"
                                            value="on"
                                            v-model="formPastor.status"
                                        />
                                        <label class="btn btn-outline-success me-3" for="option5"
                                            ><font-awesome-icon
                                                class="me-2"
                                                :icon="['fas', 'circle-check']"
                                            />
                                            Aprovar solicitação</label
                                        >

                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="options-base"
                                            id="option6"
                                            autocomplete="off"
                                            value="off"
                                            v-model="formPastor.status"
                                        />
                                        <label class="btn btn-outline-danger" for="option6"
                                            ><font-awesome-icon
                                                class="me-2"
                                                :icon="['fas', 'ban']"
                                            />
                                            Recusar solicitação</label
                                        >
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label"
                                            >Observações</label
                                        >
                                        <textarea
                                            class="form-control"
                                            id="exampleFormControlTextarea1"
                                            v-model="formPastor.comments_pastor"
                                            placeholder="Caso necessário, informe algo importante nesse ponto."
                                            rows="3"
                                        ></textarea>
                                    </div>
                                </Form>
                            </div>

                            <div v-else>
                                <p
                                    class="bg-warning bg-opacity-10 border-start border-warning border-5 p-3 mt-2"
                                >
                                    Na primeira etapa, o pastor avaliará a solicitação para decidir
                                    seu encaminhamento à próxima fase com o líder do departamento.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div v-if="request.status_response_pastor == 'APPROVED'">
                        <div
                            class="bg-primary bg-opacity-10 border-start border-primary border-5 p-3 mt-3 d-flex align-items-center justify-content-between row mx-2"
                        >
                            <div class="col-md-8">
                                <font-awesome-icon
                                    class="me-2 text-body-tertiary"
                                    :icon="['fas', 'user-check']"
                                />
                                <span class="me-2 font-semibold fs-12"
                                    >Solicitação inicial aprovada por
                                    {{ request.pastor.clean_name }}
                                </span>
                                <img
                                    width="30"
                                    :src="`${request.pastor?.profile_image_url}`"
                                    class="img-fluid rounded-circle border border-3 border-warning"
                                    alt=""
                                />
                            </div>
                            <span
                                class="mx-2 mx-sm-0 mt-3 mt-md-0 font-semibold fs-12 col-md-4 text-end"
                            >
                                <font-awesome-icon class="me-2" :icon="['fas', 'clock']" /> Em:
                                {{ request.pastor_analysis_date }}</span
                            >
                        </div>
                        <hr />
                    </div>
                    <div v-else-if="request.status_response_pastor == 'NOT_APPROVED'">
                        <div
                            class="bg-danger bg-opacity-10 border-start border-danger border-5 p-3 mt-3"
                        >
                            <div class="d-flex align-items-center justify-content-between row">
                                <div class="col-md-8">
                                    <font-awesome-icon
                                        class="me-2 text-body-tertiary"
                                        :icon="['fas', 'user-check']"
                                    />
                                    <span class="me-2 font-semibold fs-12"
                                        >Solicitação inicial reprovada por
                                        {{ request.pastor.clean_name }}</span
                                    >
                                    <img
                                        width="30"
                                        :src="`${request.pastor?.profile_image_url}`"
                                        class="img-fluid rounded-circle border border-2 border-warning"
                                        alt=""
                                    />
                                </div>
                                <span
                                    class="mx-2 mx-sm-0 mt-3 mt-md-0 font-semibold fs-12 col-md-4 text-end"
                                >
                                    <font-awesome-icon class="me-2" :icon="['fas', 'clock']" /> Em:
                                    {{ request.pastor_analysis_date }}</span
                                >
                            </div>
                            <hr class="" />
                            <div>
                                Infelizmente, essa solicitação foi recusada já na primeira etapa
                                pelo pastor. Assim, não será possível dar continuidade ao processo
                                de inclusão deste membro no departamento. Para mais informações,
                                consulte o campo de observações abaixo ou entre em contato
                                diretamente com o pastor.
                            </div>
                            <div v-if="request.comments_pastor">
                                <hr />
                                <div
                                    class="bg-danger bg-opacity-10 border-start border-danger border-5 p-3"
                                >
                                    {{ request.comments_pastor }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="accordion-item border"
                    v-if="
                        request.status_response_leader == 'PENDING' &&
                        request.status_response_pastor == 'APPROVED'
                    "
                >
                    <h2 class="accordion-header">
                        <button
                            class="accordion-button font-semibold"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseTwo"
                        >
                            A solicitação agora só precisa ser aprovada pelo líder do departamento
                        </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse">
                        <div class="accordion-body">
                            <div v-if="isLeader">
                                Como líder do departamento, você agora pode decidir se deseja
                                <strong>aprovar</strong> ou <strong>recusar</strong> a entrada desse
                                membro em seu departamento. Ao aprovar, ele será automaticamente
                                incluído no seu departamento. Se optar por recusar, a solicitação
                                será finalizada nesse ponto.

                                <p
                                    class="bg-warning bg-opacity-10 border-start border-warning border-5 p-3 mt-3"
                                >
                                    Ao recusar a solicitação, recomendamos que informe o motivo no
                                    campo de observações.
                                </p>

                                <hr />

                                <Form
                                    :method="sendResponseRequestLeader"
                                    class="border-0 shadow-none mt-0 pt-0"
                                    buttonName="Enviar solicitação"
                                >
                                    <div class="d-flex justify-content-center">
                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="options-base"
                                            id="option5"
                                            autocomplete="off"
                                            value="on"
                                            v-model="formLeader.status"
                                        />
                                        <label class="btn btn-outline-success me-3" for="option5"
                                            ><font-awesome-icon
                                                class="me-2"
                                                :icon="['fas', 'circle-check']"
                                            />
                                            Aprovar solicitação</label
                                        >

                                        <input
                                            type="radio"
                                            class="btn-check"
                                            name="options-base"
                                            id="option6"
                                            autocomplete="off"
                                            value="off"
                                            v-model="formLeader.status"
                                        />
                                        <label class="btn btn-outline-danger" for="option6"
                                            ><font-awesome-icon
                                                class="me-2"
                                                :icon="['fas', 'ban']"
                                            />
                                            Recusar solicitação</label
                                        >
                                    </div>

                                    <div class="mb-3">
                                        <label for="exampleFormControlTextarea1" class="form-label"
                                            >Observações</label
                                        >
                                        <textarea
                                            class="form-control"
                                            id="exampleFormControlTextarea1"
                                            v-model="formLeader.comments_pastor"
                                            placeholder="Caso necessário, informe algo importante nesse ponto."
                                            rows="3"
                                        ></textarea>
                                    </div>
                                </Form>
                            </div>
                            <div v-else>
                                <p
                                    class="bg-warning bg-opacity-10 border-start border-warning border-5 p-3 mt-2"
                                >
                                    Esta segunda etapa da solicitação será gerenciada exclusivamente
                                    pelo líder do departamento.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div v-if="request.status_response_leader == 'APPROVED'">
                        <div
                            class="bg-primary bg-opacity-10 border-start border-primary border-5 p-3 mt-3 d-flex align-items-center justify-content-between row mx-2"
                        >
                            <div class="col-md-8">
                                <font-awesome-icon
                                    class="me-2 text-body-tertiary"
                                    :icon="['fas', 'user-check']"
                                />
                                <span class="me-2 font-semibold fs-12"
                                    >Solicitação final aprovada por
                                    {{ request.leader.clean_name }}</span
                                >
                                <img
                                    width="30"
                                    :src="`${request.leader?.profile_image_url}`"
                                    class="img-fluid rounded-circle border border-3 border-warning"
                                    alt=""
                                />
                            </div>
                            <span
                                class="mx-2 mx-sm-0 mt-3 mt-md-0 font-semibold fs-12 col-md-4 text-end"
                            >
                                <font-awesome-icon class="me-2" :icon="['fas', 'clock']" /> Em:
                                {{ request.leader_analysis_date }}</span
                            >
                        </div>
                        <hr />
                    </div>
                    <div v-else-if="request.status_response_leader == 'NOT_APPROVED'">
                        <div
                            class="bg-danger bg-opacity-10 border-start border-danger border-5 p-3 mt-3 mx-2"
                        >
                            <div class="d-flex align-items-center justify-content-between row">
                                <div class="col-md-8">
                                    <font-awesome-icon
                                        class="me-2 text-body-tertiary"
                                        :icon="['fas', 'user-check']"
                                    />
                                    <span class="me-2 font-semibold fs-12"
                                        >Solicitação final reprovada por
                                        {{ request.leader.clean_name }}</span
                                    >
                                    <img
                                        width="30"
                                        :src="`${request.leader?.profile_image_url}`"
                                        class="img-fluid rounded-circle border border-2 border-warning"
                                        alt=""
                                    />
                                </div>
                                <span
                                    class="mx-2 mx-sm-0 mt-3 mt-md-0 font-semibold fs-12 col-md-4 text-end"
                                >
                                    <font-awesome-icon class="me-2" :icon="['fas', 'clock']" /> Em:
                                    {{ request.leader_analysis_date }}</span
                                >
                            </div>
                            <hr class="" />
                            <div>
                                Infelizmente, essa solicitação foi recusada pelo líder do
                                departamento. Assim, não será possível dar continuidade ao processo
                                de inclusão deste membro no departamento. Para mais informações,
                                consulte o campo de observações abaixo ou entre em contato
                                diretamente com o líder do departamento.
                            </div>
                            <div v-if="request.comments_leader">
                                <hr />
                                <div
                                    class="bg-warning bg-opacity-10 border-start border-warning border-5 p-3"
                                >
                                    {{ request.comments_leader }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CreatorRecord
                    v-if="creatorRegistration.name"
                    :data="creatorRegistration"
                    title="Solicitação feita por: "
                />
            </div>
        </div>
    </Dashboard>
</template>
