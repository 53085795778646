<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

import useDateFormat from '@/Pages/Dashboard/Composables/useDateFormat.vue';

const { simplifyDate } = useDateFormat();

const props = defineProps({
    events: Object,
    counselors: Object,
    typesNewBirths: Object,
    maritalStatus: Object,
    members: Object,
    spouses: Object,
    groupMeetingsConnections: Object,
});

const mounted = ref(false);

const form = useForm({
    name: null,
    event_id: props.events[0]?.id || null,
    type: props.typesNewBirths[0].value || null,
    marital_status: props.maritalStatus[0].value || null,
    new_birth_date: null,
    counselors: [],
    neighborhood: null,
    number: null,
    address: null,
    member_id: null,
    spouse_id: null,
    spouse_name: null,
    observations: null,
    group_connection_meeting_id: null,
});

const seacher = reactive({
    member_name: null,
    spouse_name: null,
});

const search = () => {
    router.visit('/dashboard/new-births/create', {
        method: 'get',
        data: { seacher },
        only: ['members', 'spouses'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.member_id = props.members ? form.member_id : null;
            form.spouse_id = props.spouses ? form.spouse_id : null;

            member.value = false;
            newBirthAlreadyExists.value = false;
            addressExists.value = false;
        },
    });
};

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const store = () =>
    form
        .transform((data) => ({
            ...data,
            spouse_name: form.marital_status == 'MARRIED' ? form.spouse_name : '',
            spouse_id: form.marital_status == 'MARRIED' ? form.spouse_id : '',
        }))
        .post(`/dashboard/new-births`);

const member = ref(false);
const newBirthAlreadyExists = ref(false);
const addressExists = ref(false);

const toggleCounselorSelection = (counselorId) => {
    const index = form.counselors.indexOf(counselorId);
    return index === -1 ? form.counselors.push(counselorId) : form.counselors.splice(index, 1);
};

const toggleSelection = (field, id) => {
    if (field === 'member_id') {
        member.value = props.members.find((v) => v.id == id);

        if (member.value.marital_status) {
            form.marital_status = props.maritalStatus.find(
                (m) => m.value == member.value.marital_status
            ).value;
        }

        const existingNewBirth = member.value.new_births?.find((nb) => nb.type == 'NEW_BIRTH');
        if (existingNewBirth) {
            form.type = props.typesNewBirths.find((t) => t.value == 'RECONCCILIATION').value;
            newBirthAlreadyExists.value = true;
        }

        if (member.value.family?.addresses?.[0]) {
            const address = member.value.family.addresses[0];
            Object.assign(form, {
                address: address.address,
                neighborhood: address.neighborhood,
                number: address.number,
            });
            addressExists.value = true;
        }
    }
    if (form[field] === id) {
        form[field] = null;
        member.value = false;
        newBirthAlreadyExists.value = false;
        addressExists.value = false;
    } else {
        form[field] = id;
    }
};

const clearQueryParams = () => router.replace('/dashboard/new-births/create');

onMounted(() => {
    clearQueryParams();
    setInterval(() => (mounted.value = true), 2000);
});
</script>

<template>
    <Dashboard page-title="Novo nascimento">
        <Breadcrumb
            current-page-name="Novo nascimento"
            :link-list="[
                {
                    previous_page_url: 'new-births',
                    previous_page_name: 'Novos nascimentos',
                },
            ]"
        />
        <h4 class="my-3">Novo nascimento</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing || !form.member_id"
        >
            <div
                v-if="newBirthAlreadyExists"
                class="p-3 bg-info bg-opacity-10 border border-info rounded"
            >
                {{ member.name }} já tem um registro de novo nascimento cadastrado. Agora, ele só
                pode ter um novo registro de nascimento voltado à reconciliação!
            </div>

            <div class="row g-2">
                <div class="col-md-8">
                    <label for="name" class="form-label">Nome</label>
                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="João Pedro de Souza"
                        required
                        @input="compositionSeacher('member_name', $event)"
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="type" class="form-label">Tipo</label>
                    <select
                        id="type"
                        v-model="form.type"
                        :disabled="newBirthAlreadyExists"
                        class="form-select"
                    >
                        <option
                            v-for="(item, index) in typesNewBirths"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>

            <template v-if="mounted && members?.length">
                <hr />

                <legend class="m-0">Membro relacionado</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in members"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.member_id === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.member_id === item.id"
                            @change="toggleSelection('member_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="`/dashboard/members/${item.id}`"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar perfil</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>
                <hr class="my-0" />
            </template>

            <div class="col-md-6">
                <label for="address" class="form-label">Endereço</label>
                <input
                    id="address"
                    v-model="form.address"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Rua São Jorge"
                    :disabled="addressExists"
                    required
                />
                <small v-if="form.errors.address" class="text-danger mt-3">
                    {{ form.errors.address }}
                </small>
            </div>

            <div class="col-md-3">
                <label for="number" class="form-label">Número</label>
                <input
                    id="number"
                    v-model="form.number"
                    type="number"
                    autocomplete="off"
                    class="form-control"
                    placeholder="120"
                    :disabled="addressExists"
                />
                <small v-if="form.errors.number" class="text-danger mt-3">
                    {{ form.errors.number }}
                </small>
            </div>

            <div class="col-md-3">
                <label for="neighborhood" class="form-label">Bairro</label>
                <input
                    id="neighborhood"
                    v-model="form.neighborhood"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Centro"
                    :disabled="addressExists"
                />
                <small v-if="form.errors.neighborhood" class="text-danger mt-3">
                    {{ form.errors.neighborhood }}
                </small>
            </div>

            <div class="col-md-8">
                <label for="type" class="form-label">Evento relacionado</label>
                <select id="type" v-model="form.event_id" class="form-select" required>
                    <option v-for="(item, index) in events" :key="index" :value="item.id">
                        {{ item.schedule.name }} - {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-4">
                <label for="type" class="form-label">Estado civil</label>
                <select id="type" v-model="form.marital_status" class="form-select">
                    <option v-for="(item, index) in maritalStatus" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-12">
                <label for="observations" class="form-label"> Observações </label>

                <textarea
                    id="observations"
                    v-model="form.observations"
                    type="text"
                    class="form-control"
                    name="observations"
                    placeholder="Caso necessário, forneca alguma observação para esse registro"
                    rows="5"
                />

                <small v-if="form.errors.observations" class="text-danger mt-3">
                    {{ form.errors.observations }}
                </small>
            </div>

            <div
                v-if="form.marital_status == 'MARRIED' && member.marital_status != 'MARRIED'"
                class="col-md-12"
            >
                <label for="spouse_id" class="form-label">Nome Cônjuge</label>
                <input
                    id="spouse_id"
                    v-model="form.spouse_name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder=""
                    @input="compositionSeacher('spouse_name', $event)"
                />
                <small v-if="form.errors.spouse_name" class="text-danger mt-3">
                    {{ form.errors.spouse_name }}
                </small>
            </div>

            <template v-if="mounted && spouses?.length">
                <hr />

                <legend class="m-0">Cônjuge relacionado</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in spouses"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.spouse_id === item.id }"
                    >
                        <input
                            :id="'checkBoxSpouse' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.spouse_id === item.id"
                            @change="toggleSelection('spouse_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxSpouse' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="`/dashboard/members/${item.id}`"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar perfil</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>
            </template>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione os conselheiros</legend>

            <div class="row">
                <div
                    v-for="(item, index) in counselors"
                    :key="index"
                    class="col-md-2 col-6 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`/storage/${item.identification_photo}`"
                            :class="{
                                selected: form.counselors.includes(item.id),
                            }"
                            @click="toggleCounselorSelection(item.id)"
                        />
                        <span
                            v-show="form.counselors.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>

            <template v-if="groupMeetingsConnections.length">
                <hr />
                <legend class="m-0">Encontro de Gc relacionado</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in groupMeetingsConnections"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.group_connection_meeting_id === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.group_connection_meeting_id === item.id"
                            @change="toggleSelection('group_connection_meeting_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span
                                    >{{ item.group_connection_name }} -
                                    {{ simplifyDate(item.meeting_date) }}</span
                                >
                                <a
                                    :href="`/dashboard/group-connections/${item.group_connection_id}`"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar grupo</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>
            </template>
        </Form>
    </Dashboard>
</template>
