<script setup>
import { computed } from 'vue';
import { Link, router } from '@inertiajs/vue3';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';
import CreatorRecord from '@/Pages/Dashboard/Shared/CreatorRecord.vue';

import usePriceFormatter from '@/Pages/Dashboard/Composables/usePriceFormatter.vue';

const convertYouTubeLink = (link) => {
    if (link && link.includes('/watch?v=')) {
        link = link.replace('/watch?v=', '/embed/');
        link = link.split('&')[0];
    }
    return link;
};

const props = defineProps({
    event: Object,
    musicalPlanning: Object,
    membersEvent: Object,
    scales: Object,
    canExport: Boolean,
    creatorRegistration: Object,
});

const { convertToCurrencyFormat } = usePriceFormatter();

const goToVisitorPage = (memberId) => router.get(`/dashboard/visitors/${memberId}`);
const goToNewBirthPage = (memberId) => router.get(`/dashboard/new-births/${memberId}`);
const goToMemberEventPage = (memberId) =>
    router.get(`/dashboard/events/${props.event.id}/members/${memberId}`);
const goToMusicPage = (musicId) => router.get(`/dashboard/musics/${musicId}`);

const totalMembersServing = computed(() => {
    return props.scales.reduce((total, item) => total + item.members.length, 0);
});
</script>

<template>
    <Dashboard page-title="Detalhes do evento">
        <div id="show-event">
            <Breadcrumb
                :current-page-name="event.name"
                :link-list="[
                    {
                        previous_page_url: 'events',
                        previous_page_name: 'Eventos',
                    },
                ]"
            />
            <div class="container-youtube" v-if="event.type != 'PAID_EVENT'">
                <div class="ratio ratio-16x9 ratio-xxl-21x9 position-relative">
                    <iframe
                        v-if="event.youtube_url"
                        class="video-youtube"
                        frameborder="0"
                        :src="`${convertYouTubeLink(event.youtube_url)}?autoplay=1&mute=1&controls=1&showinfo=1&modestbranding=1&rel=0&iv_load_policy=3`"
                    />

                    <div class="overlay position-absolute top-0 start-0" />
                    <div
                        class="content position-absolute d-flex align-items-center text-white text-start"
                    >
                        <div class="col-8 ms-5">
                            <h2 class="mb-0 d-none d-sm-block">
                                {{ event.name }}
                            </h2>
                            <span class="badge my-3 ms-sm-0 ms-4"
                                >Realizado em {{ event.start_time }}</span
                            >
                            <p class="d-none d-sm-block">
                                {{ event.details }}
                            </p>
                            <div
                                class="d-flex justify-content-center justify-content-sm-start container-buttons"
                            >
                                <a
                                    target="_blank"
                                    :href="event.youtube_url"
                                    :class="[
                                        'btn',
                                        'text-white',
                                        'shadow-sm',
                                        { disabled: !event.youtube_url },
                                    ]"
                                >
                                    <font-awesome-icon class="me-2" :icon="['fab', 'youtube']" />
                                    <span> Assitir no Youtube </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="event.type == 'PAID_EVENT'">
                <div class="card-simple px-4 py-2">
                    <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">Pessoas inscritas</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end pe-0">
                            <Link
                                :href="`/dashboard/events/${event.id}/members/create`"
                                class="btn btn-primary me-2 btn-add"
                            >
                                <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                                Nova
                            </Link>
                        </div>
                    </div>
                    <hr />
                    <div
                        class="row d-flex justify-content-between align-items-center mt-2"
                        v-if="canExport"
                    >
                        <div class="font-bold col-12 col-sm-6">Baixar relatório</div>
                        <div
                            class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start"
                        >
                            <a
                                class="btn btn-danger fw-bold fs-12"
                                :href="`/dashboard/events/${event.id}/members/export/pdf`"
                            >
                                <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                            </a>
                            <a
                                class="btn btn-success ms-3 fw-bold fs-12"
                                :href="`/dashboard/events/${event.id}/members/export/excel`"
                            >
                                <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" />
                                Excel
                            </a>
                        </div>
                    </div>
                    <div class="row shadow-none search-form-card border-0 my-3">
                        <div class="table-responsive">
                            <table
                                class="table m-0 align-middle table-hover"
                                id="table-member-events"
                            >
                                <thead class="table-light">
                                    <tr>
                                        <th class="text-center" scope="col">#</th>
                                        <th scope="col">Nome do inscrito</th>
                                        <th class="text-center" scope="col">Valor Pago</th>
                                        <th scope="col" class="text-center">Método</th>
                                        <th scope="col" class="text-center">Data</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr
                                        v-for="(member, index) in membersEvent.data"
                                        :key="index"
                                        @click="goToMemberEventPage(member.id)"
                                    >
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td>
                                            <img
                                                class="rounded-circle me-3"
                                                width="30"
                                                :src="member.identification_photo"
                                                alt=""
                                            />
                                            <span>
                                                {{ member.name }}
                                            </span>
                                        </td>
                                        <td class="text-center">
                                            {{ convertToCurrencyFormat(member.amount_paid) }}
                                        </td>
                                        <td class="text-center">
                                            <Badge :name="member.payment_method" />
                                        </td>
                                        <td class="text-center">
                                            {{ member.created_at }}
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot class="bg-white mt-3">
                                    <tr>
                                        <td colspan="5">
                                            <Pagination
                                                :data="membersEvent.meta"
                                                reference-date="membersEvent"
                                            />
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <ul id="myTab" class="nav nav-tabs mt-4 d-flex justify-content-center" role="tablist">
                <li class="nav-item" role="presentation">
                    <button
                        id="event-details"
                        class="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#event-details-pane"
                        type="button"
                        role="tab"
                        aria-controls="event-details-pane"
                        aria-selected="true"
                    >
                        Resumo
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button
                        id="scale-tab"
                        class="nav-link d-flex align-items-center"
                        data-bs-toggle="tab"
                        data-bs-target="#scale-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="scale-tab-pane"
                        aria-selected="false"
                    >
                        <span>Equipe Escalada</span>
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button
                        id="profile-tab"
                        class="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#censu-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="censu-tab-pane"
                        aria-selected="false"
                    >
                        Censo
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button
                        id="visitor-tab"
                        class="nav-link d-flex align-items-center"
                        data-bs-toggle="tab"
                        data-bs-target="#visitor-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="visitor-tab-pane"
                        aria-selected="false"
                    >
                        <span
                            v-if="event.visitors.length > 0"
                            class="badge text-bg-secondary me-2"
                            >{{ event.visitors.length }}</span
                        >
                        <span>Visitantes</span>
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button
                        id="new-birth-tab"
                        class="nav-link d-flex align-items-center"
                        data-bs-toggle="tab"
                        data-bs-target="#new-birth-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="new-birth-tab-pane"
                        aria-selected="false"
                    >
                        <span
                            v-if="event.visitors.length > 0"
                            class="badge text-bg-secondary me-2"
                            >{{ event.new_births.length }}</span
                        >
                        <span>Conversões</span>
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button
                        id="music-tab"
                        class="nav-link d-flex align-items-center"
                        data-bs-toggle="tab"
                        data-bs-target="#music-tab-pane"
                        type="button"
                        :disabled="!musicalPlanning.musics?.length"
                        role="tab"
                        aria-controls="music-tab-pane"
                        aria-selected="false"
                    >
                        <span>Louvores</span>
                    </button>
                </li>
            </ul>

            <div id="myTabContent" class="tab-content mt-3">
                <div
                    id="event-details-pane"
                    class="tab-pane fade show active card p-3"
                    role="tabpanel"
                    aria-labelledby="event-details"
                    tabindex="0"
                >
                    <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                        <div class="col-sm-6">
                            <h4 class="font-bold m-0">{{ event.name }}</h4>
                        </div>
                        <div class="col-sm-6 mt-3 mt-sm-0 text-end pe-0">
                            <Link
                                :href="`/dashboard/events/edit/${event.id}`"
                                class="btn btn-primary me-3 btn-add"
                            >
                                <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                                Editar dados
                            </Link>
                        </div>
                    </div>

                    <hr class="mt-0 pt-0 px-5 d-block" />

                    <div class="row">
                        <div class="col-sm-7 border-end">
                            <form class="my-3">
                                <fieldset disabled>
                                    <div class="row g-3 px-3">
                                        <legend class="mb-0">Detalhes do evento</legend>
                                        <div class="col-md-8">
                                            <label for="disabledTextInput" class="form-label"
                                                >Nome do evento</label
                                            >
                                            <input
                                                id="disabledTextInput"
                                                type="text"
                                                :value="event.name"
                                                class="form-control"
                                                placeholder="Disabled input"
                                            />
                                        </div>

                                        <div class="col-md-4">
                                            <label
                                                for="disabledTextInput"
                                                class="form-label me-3 me-sm-0"
                                                >Tipo de evento</label
                                            >
                                            <Badge :name="event.type" />
                                        </div>
                                        <div class="col-md-12">
                                            <label for="details" class="form-label">
                                                Detalhes
                                            </label>

                                            <textarea
                                                id="details"
                                                type="text"
                                                class="form-control"
                                                name="details"
                                                :value="event.details"
                                                rows="5"
                                            />
                                        </div>
                                    </div>
                                    <hr />

                                    <div v-if="event.eventMinisters" class="row g-3 px-3">
                                        <legend class="mb-0">Detalhes da ministração</legend>
                                        <div class="col-md-12">
                                            <label for="disabledTextInput" class="form-label"
                                                >Tema da ministração</label
                                            >
                                            <input
                                                id="disabledTextInput"
                                                type="text"
                                                :value="
                                                    event.eventMinisters[0]?.theme || event.name
                                                "
                                                class="form-control"
                                                placeholder="Disabled input"
                                            />
                                        </div>
                                        <div class="col-md-12">
                                            <label for="details" class="form-label">
                                                Detalhes
                                            </label>

                                            <textarea
                                                id="details"
                                                type="text"
                                                class="form-control"
                                                name="details"
                                                :value="event.eventMinisters[0]?.details"
                                                rows="5"
                                            />
                                        </div>
                                    </div>

                                    <hr />

                                    <div class="row g-3 px-3">
                                        <legend class="mb-0">Resumo do evento</legend>
                                        <div class="col-md-12">
                                            <textarea
                                                id="details"
                                                type="text"
                                                class="form-control"
                                                name="details"
                                                :value="event.summary"
                                                rows="5"
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <div class="col-sm-5">
                            <div class="mt-4">
                                <div class="card-banner d-block mx-auto bg-secondary">
                                    <img
                                        v-if="event.banner"
                                        class="shadow-sm banner"
                                        :src="`/storage/${event.banner}`"
                                        alt=""
                                    />
                                </div>
                                <div class="event-organizers">
                                    <h4
                                        v-if="event.ministers.length || event.leaders.length"
                                        class="my-4 justify-content-center d-flex align-items-center"
                                    >
                                        <font-awesome-icon
                                            class="me-3 text-secondary"
                                            :icon="['fas', 'users-line']"
                                        />
                                        Organizadores
                                    </h4>

                                    <template v-if="event.ministers.length">
                                        <div
                                            v-for="(minister, index) in event.ministers"
                                            :key="index"
                                            class="d-flex align-items-center justify-content-start mt-4 ms-3"
                                        >
                                            <Link :href="`/dashboard/ministers/${minister.id}`">
                                                <img
                                                    class="image-organizer border border-info border-3"
                                                    :src="`/storage/${minister.identification_photo}`"
                                                    alt=""
                                                />
                                            </Link>

                                            <div class="ms-3">
                                                <span class="name d-block">
                                                    {{ minister.name }}
                                                </span>
                                                <span class="office d-block"> Ministro </span>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="event.leaders.length">
                                        <div
                                            v-for="(leader, index) in event.leaders"
                                            :key="index"
                                            class="d-flex align-items-center justify-content-start mt-4 ms-3"
                                        >
                                            <Link :href="`/dashboard/leaders/${leader.id}`">
                                                <img
                                                    class="image-organizer border border-info border-3"
                                                    :src="`/storage/${leader.identification_photo}`"
                                                    alt=""
                                                />
                                            </Link>
                                            <div class="ms-3">
                                                <span class="name d-block">
                                                    {{ leader.name }}
                                                </span>
                                                <span class="office d-block">
                                                    Dirigente do Evento
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="censu-tab-pane"
                    class="tab-pane fade card p-3"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    tabindex="0"
                >
                    <fieldset v-if="event.censu" class="my-3" disabled="disabled">
                        <div class="row g-3 px-3">
                            <legend class="m-0">
                                Distribuição dos {{ event.censu.total_people }} presentes
                            </legend>

                            <div class="col-md-4">
                                <label for="number_people_present" class="form-label">
                                    Pessoas no templo
                                </label>

                                <input
                                    id="number_people_present"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_people_present"
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>

                            <div class="col-md-4">
                                <label for="number_visitors" class="form-label"> Visitantes </label>

                                <input
                                    id="number_visitors"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_visitors || 'Nenhum'"
                                    min="0"
                                    max="100000"
                                />
                            </div>

                            <div class="col-md-4">
                                <label for="number_people_saved" class="form-label">
                                    Pessoas salvas
                                </label>

                                <input
                                    id="number_people_saved"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_people_saved || 'Nenhuma'"
                                    min="0"
                                    max="100000"
                                />
                            </div>

                            <div class="col-md-3">
                                <label for="number_people_cured" class="form-label">
                                    Pessoas curadas
                                </label>

                                <input
                                    id="number_people_cured"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_people_cured || 'Nenhuma'"
                                    min="0"
                                    max="100000"
                                />
                            </div>

                            <div class="col-md-5">
                                <label for="number_people_baptized_holy_spirit" class="form-label">
                                    Pessoas batizadas no Espírito Santo
                                </label>

                                <input
                                    id="number_people_baptized_holy_spirit"
                                    type="text"
                                    class="form-control"
                                    :value="
                                        event.censu.number_people_baptized_holy_spirit || 'Nenhuma'
                                    "
                                    min="0"
                                    max="100000"
                                />
                            </div>

                            <div class="col-md-4">
                                <label for="number_people_youtube" class="form-label">
                                    Pessoas no YouTube
                                </label>

                                <input
                                    id="number_people_youtube"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_people_youtube || 'Nenhuma'"
                                    min="0"
                                    max="100000"
                                />
                            </div>
                            <hr class="my-4" />
                        </div>

                        <div class="row g-3 px-3 mt-2">
                            <legend class="m-0">Crianças e Adolescentes</legend>

                            <div class="col-md-6">
                                <label for="number_visitors_children" class="form-label">
                                    Crianças Visitantes
                                </label>

                                <input
                                    id="number_visitors_children"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_visitors_children || 'Nenhuma'"
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>

                            <div class="col-md-6">
                                <label for="number_gv_teens" class="form-label">
                                    Adolescentes GV
                                </label>

                                <input
                                    id="number_gv_teens"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_gv_teens || 'Nenhum'"
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>

                            <div class="col-md-6">
                                <label
                                    for="number_children_zero_to_two_years_old"
                                    class="form-label"
                                >
                                    Crianças de 0 a 2 anos
                                </label>

                                <input
                                    id="number_children_zero_to_two_years_old"
                                    type="text"
                                    class="form-control"
                                    :value="
                                        event.censu.number_children_zero_to_two_years_old ||
                                        'Nenhuma'
                                    "
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>

                            <div class="col-md-6">
                                <label for="number_gv_teens_saved" class="form-label">
                                    Adolescentes GV salvos
                                </label>

                                <input
                                    id="number_gv_teens_saved"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_gv_teens_saved || 'Nenhuma'"
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>

                            <div class="col-md-6">
                                <label
                                    for="number_children_three_to_six_years_old"
                                    class="form-label"
                                >
                                    Crianças de 3 a 6 anos
                                </label>

                                <input
                                    id="number_children_three_to_six_years_old"
                                    type="text"
                                    class="form-control"
                                    :value="
                                        event.censu.number_children_three_to_six_years_old ||
                                        'Nenhuma'
                                    "
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>

                            <div class="col-md-6">
                                <label for="number_children_saved" class="form-label">
                                    Crianças salvas
                                </label>

                                <input
                                    id="number_children_saved"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_children_saved || 'Nenhuma'"
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>

                            <div class="col-md-6">
                                <label
                                    for="number_children_seven_to_twelve_years_old"
                                    class="form-label"
                                >
                                    Crianças de 7 a 12 anos
                                </label>

                                <input
                                    id="number_children_seven_to_twelve_years_old"
                                    type="text"
                                    class="form-control"
                                    :value="
                                        event.censu.number_children_seven_to_twelve_years_old ||
                                        'Nenhuma'
                                    "
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>
                            <hr class="my-4" />
                        </div>

                        <div class="row g-3 px-3 mt-2">
                            <legend class="m-0">Outros dados</legend>

                            <div class="col-md-6">
                                <label for="number_teachers" class="form-label">
                                    Professores presentes
                                </label>

                                <input
                                    id="number_teachers"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_teachers || 'Nenhum'"
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>

                            <div class="col-md-6">
                                <label for="number_assistant_teachers" class="form-label">
                                    Assistentes de professores presentes
                                </label>

                                <input
                                    id="number_assistant_teachers"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_assistant_teachers || 'Nenhum'"
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>

                            <div class="col-md-6">
                                <label for="number_leaders" class="form-label">
                                    Líderes presentes
                                </label>

                                <input
                                    id="number_leaders"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_leaders || 'Nenhum'"
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>

                            <div class="col-md-6">
                                <label for="number_secretariats" class="form-label">
                                    Secretários presentes
                                </label>

                                <input
                                    id="number_secretariats"
                                    type="text"
                                    class="form-control"
                                    :value="event.censu.number_secretariats || 'Nenhum'"
                                    min="0"
                                    max="100000"
                                    required
                                />
                            </div>
                        </div>
                    </fieldset>

                    <div v-else>
                        <p
                            class="p-3 bg-info bg-opacity-10 rounded-end informative-text border-primary border-start border-5"
                        >
                            Nenhum censo foi associado a este evento até o momento
                        </p>

                        <div class="d-flex justify-content-end">
                            <Link
                                class="btn btn-primary btn-redirection"
                                :href="`/dashboard/census/create/${event.id}`"
                            >
                                Adicionar censo
                            </Link>
                        </div>
                    </div>
                </div>

                <div
                    id="visitor-tab-pane"
                    class="tab-pane fade search-form-card"
                    role="tabpanel"
                    aria-labelledby="visitor-tab"
                    tabindex="0"
                >
                    <div class="table-responsive" v-if="event.visitors.length">
                        <table class="table align-middle table-hover" id="table-event-visitors">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome</th>
                                    <th class="text-center" scope="col">Sexo</th>
                                    <th class="text-center" scope="col">Telefone</th>
                                    <th class="text-center" scope="col">Crente</th>
                                    <th class="text-center" scope="col">Indicação</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(visitor, index) in event.visitors"
                                    :key="index"
                                    @click="goToVisitorPage(visitor.id)"
                                >
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ visitor.member.name }}</td>
                                    <td class="text-center">
                                        <Badge :name="visitor.member.sex" />
                                    </td>
                                    <td class="text-center">
                                        {{
                                            visitor.member.phone_number?.phone_number ??
                                            'Não informado'
                                        }}
                                    </td>
                                    <td class="text-center">
                                        <Badge :name="visitor.member.believer ? 'YES' : 'NOT'" />
                                    </td>
                                    <td class="text-center">
                                        <Badge :name="visitor.met_church" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p
                        v-else
                        class="p-3 m-3 bg-info bg-opacity-10 rounded-end informative-text border-primary border-start border-5"
                    >
                        Nenhum visitante foi associado a este evento até o momento.
                    </p>
                </div>

                <div
                    id="new-birth-tab-pane"
                    class="tab-pane fade search-form-card"
                    role="tabpanel"
                    aria-labelledby="new-birth-tab"
                    tabindex="0"
                >
                    <div class="table-responsive" v-if="event.new_births.length">
                        <table class="table align-middle table-hover" id="table-event-new-births">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nome do membro</th>
                                    <th class="text-center" scope="col">Tipo de novo nascimento</th>
                                    <th class="text-center" scope="col">Conselheiros</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(new_birth, index) in event.new_births"
                                    :key="index"
                                    @click="goToNewBirthPage(new_birth.id)"
                                >
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ new_birth.member.name }}</td>
                                    <td class="text-center">
                                        <Badge :name="new_birth.type" />
                                    </td>
                                    <td class="text-center">
                                        <template v-if="new_birth.counselors.length">
                                            <Link
                                                v-for="(counselor, index) in new_birth.counselors"
                                                :key="index"
                                                :href="`/dashboard/counselors/${counselor.id}`"
                                            >
                                                <img
                                                    height="30"
                                                    width="30"
                                                    class="rounded-circle border border-1 border-ligth"
                                                    :src="`/storage/${counselor.identification_photo}`"
                                                    alt=""
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="bottom"
                                                    :data-bs-title="counselor.name"
                                                    style="margin-left: -8px"
                                                />
                                            </Link>
                                        </template>
                                        <template v-else>
                                            <font-awesome-icon
                                                :icon="['fas', 'minus']"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                data-bs-title="Nenhum conselheiro definido"
                                            />
                                        </template>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p
                        v-else
                        class="p-3 m-3 bg-info bg-opacity-10 rounded-end informative-text border-primary border-start border-5"
                    >
                        Nenhum novo nascimento foi associado a este evento até o momento.
                    </p>
                </div>

                <div
                    id="scale-tab-pane"
                    class="tab-pane fade"
                    role="tabpanel"
                    aria-labelledby="scale-tab"
                    tabindex="0"
                >
                    <div class="row d-flex justify-content-between align-items-center mt-4 px-3">
                        <h5 class="font-bold col-12 col-sm-6">
                            {{ totalMembersServing }} membros escalados
                        </h5>
                        <div
                            class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start"
                        >
                            <a
                                class="btn btn-danger fw-bold fs-12"
                                :href="`/dashboard/events/${event.id}/scales/export`"
                            >
                                <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                            </a>
                        </div>
                    </div>

                    <hr />

                    <div class="row p-3" v-if="scales.length">
                        <div
                            v-for="(item, index) in scales.filter((scale) => scale.members.length)"
                            :key="index"
                            class="col-lg-6 col-xxl-4 mb-4"
                        >
                            <div class="card-simple department-card card">
                                <div class="card-header text-center p-3 font-bold">
                                    {{ item.department.name }}
                                </div>

                                <div class="card-body mb-0" style="overflow: auto; height: 300px">
                                    <ul class="list-group">
                                        <Link
                                            v-for="member in item.members"
                                            :key="member.id"
                                            class="list-group-item py-3"
                                            :href="`/dashboard/members/${member.departmentMember.member.id}`"
                                        >
                                            <img
                                                class="me-2 rounded-circle"
                                                width="30px"
                                                :src="
                                                    member.departmentMember.member
                                                        .identification_photo
                                                "
                                                alt="Foto de {{ member.departmentMember.member.clean_name }}"
                                            />
                                            <span class="font-bold fs-12">
                                                {{ member.departmentMember.member.clean_name }}
                                            </span>
                                        </Link>
                                    </ul>
                                </div>

                                <div
                                    class="card-footer d-flex justify-content-between bg-white align-items-center"
                                >
                                    <span class="font-bold fs-12">
                                        {{ item.members.length }} membro(s)
                                    </span>
                                    <Link
                                        :href="`/dashboard/departments/${item.department.id}/scales/${item.department_monthly_scale_id}`"
                                        class="btn btn-primary fs-12 font-bold"
                                    >
                                        Ver mais
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p
                        v-else
                        class="p-3 m-3 bg-info bg-opacity-10 rounded-end informative-text border-primary border-start border-5"
                    >
                        Nenhuma escala foi adicionada a este evento até o momento.
                    </p>
                </div>

                <div
                    id="music-tab-pane"
                    class="tab-pane fade search-form-card"
                    role="tabpanel"
                    aria-labelledby="music-tab"
                    tabindex="0"
                >
                    <div class="p-4">
                        <div class="row">
                            <div class="col-lg-6">
                                <h4 class="font-bold font-bold mb-0">Músicas</h4>
                                <span class="text-secondary font-semibold fs-12 mb-3"
                                    >Tocadas nesse evento</span
                                >
                            </div>
                            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                                <Link
                                    :href="`/dashboard/musical-plannings/edit/${musicalPlanning.id}`"
                                    class="btn btn-primary btn-add me-2"
                                >
                                    <font-awesome-icon
                                        class="me-2"
                                        :icon="['fas', 'pen-to-square']"
                                    />
                                    Gerenciar
                                </Link>
                            </div>
                        </div>
                        <div class="table-responsive search-form-card mt-3">
                            <table class="table m-0 align-middle table-hover" id="table-musics">
                                <thead class="table-light">
                                    <tr>
                                        <th class="text-center" scope="col">#</th>
                                        <th scope="col">Nome</th>
                                        <th class="text-center" scope="col">Youtube</th>
                                        <th class="text-center" scope="col">Duração</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr
                                        v-for="(music, musicIndex) in musicalPlanning.musics"
                                        :key="musicIndex"
                                        @click="goToMusicPage(music.id)"
                                    >
                                        <td class="text-center">
                                            {{ musicIndex + 1 }}
                                        </td>
                                        <td>
                                            <img
                                                class="rounded-pill me-3"
                                                height="35"
                                                width="35"
                                                :src="`/storage/${music.cover_photo}`"
                                                alt=""
                                            />
                                            <span>
                                                {{ music.name }}
                                            </span>
                                        </td>

                                        <td class="text-center">
                                            <a
                                                target="_blank"
                                                class="btn btn-link text-danger p-0 fs-3"
                                                :class="{ disabled: !music.youtube_link }"
                                                :href="music.youtube_link"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                data-bs-title="Assistir no Youtube"
                                            >
                                                <font-awesome-icon :icon="['fab', 'youtube']" />
                                            </a>
                                        </td>

                                        <td class="text-center">{{ music.duration }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <CreatorRecord
                    v-if="creatorRegistration.name"
                    :data="creatorRegistration"
                    title="Evento cadastrado por: "
                />
            </div>
        </div>
    </Dashboard>
</template>
