<script setup>
import { router, Link } from '@inertiajs/vue3';

import Layout from '../Layout.vue';

const props = defineProps({
    discipleship: Object,
    members: Object,
});

const goMemberPage = (discipleshipMemberID) =>
    router.get(`/dashboard/discipleships/${props.discipleship.id}/members/${discipleshipMemberID}`);

const statusFrequency = (status) => {
    const style = {
        PENDING: 'Não informado',
        COMPLETED: 'Presencial',
        COMPLETED_REMOTELY: 'Remotamente',
        NOT_COMPLETED: 'Ausente',
    };

    return style[status];
};

const formatStatusIcon = (status) => {
    const style = {
        PENDING: {
            color: 'text-body-tertiary',
        },
        COMPLETED: {
            color: 'text-success',
        },
        NOT_COMPLETED: {
            color: 'text-danger',
        },
        COMPLETED_REMOTELY: {
            color: 'text-primary',
        },
    };

    return style[status]?.color || 'text-success';
};
</script>

<template>
    <Layout :discipleship="discipleship">
        <div
            id="personal-data"
            class="tab-pane fade show active card p-4"
            role="tabpanel"
            aria-labelledby="event-details"
            tabindex="0"
        >
            <div class="row d-flex justify-content-center">
                <div v-if="members.length">
                    <div class="d-flex justify-content-between mb-2 align-items-center">
                        <h5 class="font-bold mb-0">Inscritos nessa turma</h5>
                        <Link
                            class="btn btn-primary btn-redirection"
                            :href="`/dashboard/discipleships/members/create`"
                        >
                            Novo
                        </Link>
                    </div>
                    <hr />
                    <div class="table-responsive search-form-card p-0">
                        <table class="table m-0 align-middle table-hover" id="table-families">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome do inscrito</th>
                                    <th class="text-center" scope="col">Telefone de contato</th>
                                    <th class="text-center" scope="col">Frequências</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(member, index) in members"
                                    :key="index"
                                    @click="goMemberPage(member.id)"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <img
                                            class="rounded-circle me-3"
                                            width="30"
                                            :src="member.member.identification_photo"
                                            alt=""
                                        />
                                        <span>
                                            {{ member.member.clean_name }}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <template v-if="member.member.phone_number?.phone_number">
                                            <a
                                                :href="`https://wa.me/55${member.member.phone_number.phone_number}`"
                                                target="_blank"
                                                class="btn btn-light btn-add"
                                                :class="{
                                                    disabled:
                                                        !member.member.phone_number
                                                            ?.same_whatsapp_number,
                                                }"
                                            >
                                                <font-awesome-icon :icon="['fab', 'whatsapp']" />
                                                <span class="ms-3">{{
                                                    member.member.phone_number.phone_number
                                                }}</span>
                                            </a>
                                        </template>
                                        <template v-else>
                                            <span>Não informado</span>
                                        </template>
                                    </td>

                                    <td class="text-center">
                                        <div v-if="member.frequencies.length">
                                            <font-awesome-icon
                                                :class="`fs-4 me-2 ${formatStatusIcon(item.status)}`"
                                                :icon="['fas', 'circle-check']"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                :data-bs-title="statusFrequency(item.status)"
                                                v-for="(item, index) in member.frequencies"
                                                :key="index"
                                            />
                                        </div>
                                        <div v-else>Pendente</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else>
                    <p
                        class="p-3 bg-info fs-14 bg-opacity-10 rounded-end font-semibold border-primary border-start border-5"
                    >
                        Nenhum inscrição realizada
                    </p>

                    <div class="d-flex justify-content-end">
                        <Link
                            class="btn btn-primary btn-redirection"
                            :href="`/dashboard/discipleships/members/create`"
                        >
                            Adicionar
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
