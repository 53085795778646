<script setup>
import { reactive, defineProps, onMounted, computed } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    census: Object,
    schedules: Object,
});

const searchForm = reactive({
    schedule_id: 0,
    start_date: '',
    end_date: '',
});

const goToCensuPage = (ministerId) => router.get(`/dashboard/census/${ministerId}`);
const clearQueryParams = () => router.replace('/dashboard/census');
const seacher = () => router.get('/dashboard/census', searchForm, { preserveState: true });

onMounted(() => {
    clearQueryParams();
});

const averagePeoplePerEvent = computed(() => {
    if (!props.census.data.length) {
        return 0;
    }
    const totalPeople = props.census.data.reduce((sum, censu) => sum + censu.total_people, 0);
    return Math.round(totalPeople / props.census.data.length);
});
</script>

<template>
    <Dashboard page-title="Censos">
        <Breadcrumb current-page-name="Censos" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Censos</h4>
                <Link :href="`/dashboard/census/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
                    <div class="font-bold col-12 col-sm-6">Baixar censos</div>
                    <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                        <a
                            class="btn btn-danger fw-bold fs-12"
                            :href="`/dashboard/census/pdf/export?start_date=${searchForm.start_date}&end_date=${searchForm.end_date}&schedule_id=${searchForm.schedule_id}`"
                        >
                            <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                        </a>
                        <a
                            class="btn btn-success ms-2 fw-bold fs-12"
                            :href="`/dashboard/census/excel/export?start_date=${searchForm.start_date}&end_date=${searchForm.end_date}&schedule_id=${searchForm.schedule_id}`"
                        >
                            <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                        </a>
                    </div>
                </div>
                <hr class="mx-3" />
                <div class="px-3">
                    <div class="row g-3">
                        <div class="col-sm-4">
                            <label for="schedule_id" class="form-label">Programação</label>
                            <select
                                id="schedule_id"
                                v-model="searchForm.schedule_id"
                                class="form-select"
                                required
                                @change="seacher()"
                            >
                                <option value="0">Todas as programações</option>
                                <option
                                    v-for="(item, index) in schedules"
                                    :key="index"
                                    :value="item.id"
                                >
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                        <div class="col-6 col-sm-4">
                            <label for="startDate" class="form-label">Data de início</label>
                            <input
                                id="startDate"
                                v-model="searchForm.start_date"
                                type="date"
                                class="form-control"
                                name=""
                                @change="seacher()"
                            />
                        </div>
                        <div class="col-6 col-sm-4">
                            <label for="endDate" class="form-label">Data de fim</label>
                            <input
                                id="endDate"
                                v-model="searchForm.end_date"
                                type="date"
                                class="form-control"
                                name=""
                                @change="seacher()"
                            />
                        </div>
                    </div>
                </div>

                <div class="row p-3">
                    <div class="table-responsive">
                        <table class="table m-0 align-middle table-hover" id="table-census">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">ID</th>
                                    <th class="text-center" scope="col">Programação</th>
                                    <th class="text-center" scope="col">Evento</th>
                                    <th scope="col" class="text-center">Presentes</th>
                                    <th scope="col" class="text-center">Visitantes</th>
                                    <th class="text-center" scope="col">Data</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(censu, index) in census.data"
                                    :key="index"
                                    @click="goToCensuPage(censu.id)"
                                >
                                    <td class="text-center">
                                        {{ censu.id }}
                                    </td>
                                    <td class="text-center">
                                        {{ censu.identification }}
                                    </td>
                                    <td class="text-center">
                                        <Link
                                            v-if="censu.event_id"
                                            :href="`/dashboard/events/${censu.event_id}`"
                                        >
                                            <img
                                                height="35"
                                                width="35"
                                                class="rounded-circle border border-1 border-ligth"
                                                :src="`/storage/${censu.event_banner}`"
                                                alt=""
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                :data-bs-title="censu.event_name"
                                            />
                                        </Link>
                                        <span
                                            v-else
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            data-bs-title="Não vinculado a evento"
                                        >
                                            <font-awesome-icon :icon="['fas', 'minus']" />
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        {{ censu.total_people }}
                                    </td>
                                    <td class="text-center">
                                        {{ censu.number_visitors }}
                                    </td>
                                    <td class="text-center">
                                        {{ censu.created_at }}
                                    </td>
                                </tr>
                            </tbody>

                            <tr>
                                <td class="text-center" colspan="6">
                                    Média de {{ averagePeoplePerEvent }} pessoas por culto
                                </td>
                            </tr>

                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="6">
                                        <Pagination :data="census.meta" reference-date="census" />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
