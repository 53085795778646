<script setup>
import { router, Link } from '@inertiajs/vue3';
import { ref, defineProps, computed } from 'vue';
import 'leaflet/dist/leaflet.css';

import Layout from './Layout.vue';

import { LMap, LTileLayer, LTooltip, LMarker, LIcon } from '@vue-leaflet/vue-leaflet';

const props = defineProps({
    group: Object,
    locations: Object,
});

const zoom = ref(15);

const locations = computed(() => Object.values(props.locations));
</script>

<template>
    <Layout :group="group">
        <div class="p-3">
            <h5 class="font-bold mb-3">Mapa dos membros desse grupo</h5>

            <l-map
                :center="[-9.4019589, -38.2323677]"
                class="rounded-3"
                :zoom="zoom"
                style="height: 400px"
            >
                <l-tile-layer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                ></l-tile-layer>
                <l-marker
                    v-for="(member, index) in locations"
                    :key="index"
                    :lat-lng="[member.location.latitude, member.location.longitude]"
                >
                    <l-icon :icon-anchor="[7, 7]">
                        <img
                            class="rounded-circle me-2"
                            width="30"
                            height="30"
                            :src="member.photo ? member.photo : '/img/avatars/male.png'"
                            alt=""
                        />
                    </l-icon>
                </l-marker>

                <l-marker :lat-lng="[-9.4019589, -38.2323677]">
                    <l-icon :icon-anchor="[16, 17]">
                        <div class="headline">Igreja</div>
                        <img
                            width="60"
                            height="60"
                            src="https://verbodavidavilamatilde.com.br/wp-content/uploads/2019/10/logo-Igreja-Verbo-da-Vida-fundo-Preto-01.png"
                        />
                    </l-icon>
                </l-marker>
            </l-map>
        </div>
    </Layout>
</template>
