<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

// Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    members: Object,
    discipleship: Object,
});

const form = useForm({
    member_id: null,
});

const seacher = reactive({
    member_name: null,
});

const search = () => {
    router.visit('/dashboard/discipleships/members/create', {
        method: 'get',
        data: { seacher },
        only: ['members'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.member_id = props.members ? form.member_id : null;
            member.value = false;
        },
    });
};

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const toggleSelection = (field, id) => {
    if (form[field] === id) {
        form[field] = null;
        member.value = false;
    } else {
        form[field] = id;
    }
};

const store = () => form.post(`/dashboard/discipleships/${props.discipleship.id}/members`);

const member = ref(false);

const clearQueryParams = () => router.replace('/dashboard/discipleships/members/create');

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Nova Inscrição">
        <Breadcrumb
            current-page-name="Nova Inscrição"
            :link-list="[
                {
                    previous_page_url: `discipleships/${discipleship?.id ?? ''}`,
                    previous_page_name: 'Turma',
                },
            ]"
        />
        <h4 class="my-3">Nova Inscrição</h4>

        <Form
            button-name="Nova Inscrição"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing || !form.member_id"
        >
            <template v-if="discipleship">
                <div class="row g-2">
                    <p
                        class="p-3 bg-info bg-opacity-10 rounded-end border-primary border-start border-5 font-semibold fs-12"
                    >
                        Discipulado: {{ discipleship.name }} -
                        {{ discipleship.total_registrations }} inscrições
                    </p>
                    <hr />

                    <div class="col-md-12">
                        <label for="name" class="form-label">Buscar membro</label>
                        <input
                            id="name"
                            v-model="form.name"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="João Pedro de Souza"
                            required
                            @input="compositionSeacher('member_name', $event)"
                        />
                        <small v-if="form.errors.name" class="text-danger mt-3">
                            {{ form.errors.name }}
                        </small>
                    </div>
                </div>

                <template v-if="members?.length">
                    <hr />

                    <legend class="m-0">Pessoa selecionado</legend>

                    <ul class="list-group member-found">
                        <li
                            v-for="(item, index) in members"
                            :key="index"
                            class="list-group-item p-2 d-flex align-items-center mb-3"
                            :class="{ checked: form.member_id === item.id }"
                        >
                            <input
                                :id="'checkBoxVisitor' + item.id"
                                class="form-check-input me-1 mb-0"
                                type="checkbox"
                                name="listGroupRadio"
                                :value="item.id"
                                :checked="form.member_id === item.id"
                                @change="toggleSelection('member_id', item.id)"
                            />
                            <label
                                class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                                :for="'checkBoxVisitor' + item.id"
                                style="cursor: pointer"
                            >
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ item.name }}</span>
                                    <a
                                        :href="`/dashboard/members/${item.id}`"
                                        target="_blank"
                                        class="btn btn-primary btn-add z-2 me-3"
                                        >Acessar perfil</a
                                    >
                                </div>
                            </label>
                        </li>
                    </ul>
                    <hr class="my-0" />
                </template>
            </template>
            <template v-else>
                <p
                    class="p-3 bg-danger bg-opacity-10 rounded-end border-danger border-start border-5 font-semibold fs-12"
                >
                    Nenhuma turma de discipulado aberta no momento
                </p>
            </template>
        </Form>
    </Dashboard>
</template>
